
<div class="container text-center pt-1">
<div *ngIf="evernote_connection">
  <img src="https://evernote.com/img/logo/evernote/primary.svg">
  <h2 style="font-weight: bold">Verknüpfung erfolgreich <i style="color:#a4dd5f" class="fas fa-check-circle"></i></h2>
  <p>Du kannst nun Einkaufszettel zu Evernote exportieren. Klicke dazu einfach auf das Evernote-Icon neben deinen Einkaufszetteln.</p>
</div>
  <div *ngIf="!evernote_connection">
    <h2>Verbindung zu Evernote wird hergestellt...</h2>
  </div>
</div>
