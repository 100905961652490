import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Plugins, CameraResultType, Capacitor, FilesystemDirectory, 
  CameraPhoto, CameraSource } from '@capacitor/core';
import { Einkaufszettel } from '../shared/services/einkaufszettel';
import { User } from '../shared/services/user';
import { CommunityService } from './community.service';

const { Camera, Filesystem, Storage } = Plugins;


@Injectable({
  providedIn: 'root'
})
export class PhotoService {
  public photos: Photo[] = [];
  public path: string = "";

  constructor( private storage: AngularFireStorage,private commService: CommunityService) { }

  public async addNewToGallery() {
    // Take a photo

    const capturedPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri, 
      source: CameraSource.Camera, 
      quality: 100 
    });
     const savedImageFile = await this.savePicture(capturedPhoto);
     return savedImageFile;
  }
  
  private async savePicture(cameraPhoto: CameraPhoto) { 



    const blob = await fetch(cameraPhoto.webPath).then(r => r.blob());
    const randomId = Math.random()
       .toString(36)
       .substring(2, 8);

    this.path = `${new Date().getTime()}_${randomId}`;

    return this.storage.upload(
      `einkaufszettel/${this.path}`,
      blob
    )

  }


}

export interface Photo {
  filepath: string;
  webviewPath: string;
}
