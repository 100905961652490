import { Injectable, NgZone } from '@angular/core';
import { User } from "../shared/services/user";

import { AuthService } from "../services/auth.service";
import firebase from 'firebase';
import auth = firebase.auth;

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
@Injectable({
  providedIn: 'root'
})
export class UserService {
  userData: any;


  constructor(private auth: AuthService,private afs: AngularFirestore, public afAuth:AngularFireAuth, public router: Router, public ngZone: NgZone ) {
  }


  checkVerified(){

  }





 exitCommunity(uid, comm_id){


        this.afs.collection(`community/${comm_id}/users`,ref=>ref.where('uid','==',uid)).snapshotChanges().subscribe(val=>{
          console.log(val);
          for(let obj of val){
            console.log(obj.payload.doc.id);
            this.afs.doc(`community/${comm_id}/users/${obj.payload.doc.id}`).delete().then(val=>{

                            })
            }
            this.afs.doc(`user/${uid}`).update({ communityId: '' }).then(val=>{
                                          this.router.navigate(['login']).then(()=>{
                                            location.reload();
                                          })
                                          });
      });


   }





}


export interface UserObj {
  email?: string;
  uid?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  address1?: string;
  address2?: string;
  zip?: number;
  data1?: boolean;
  data2?: boolean ;
  photoURL? : string;
  source?: string;
  displayName?: string;

}
