
  <div *ngIf="ready==0" class="container">

      <div class="d-flex p-2  justify-content-center"><img src="assets/img/ci_loader.gif" style="width:5%;height:5%" /></div>

  </div>
  <div *ngIf="ready==1">
    <div id="printbuttonp" class="container pt-4 pb-4 text-center">
      <div class="row text-white text-center">
        <p class="w-100 text-white text-center">Drucke deinen Einkaufszettel für dich oder der Person, der du helfen möchtest hier aus.<br>
          Auf dem Einkaufszettel sind alle nötigen Felder für die Bestellung sowie die wichtigsten Infos abgedruckt.
        </p>
      </div>
  <div class="row justify-content-center">
  <button class="btn text-center" (click)='openlink("https://drive.google.com/file/d/1Z2wOpvOLcmr7sFEksctf1gqwZXxShA7E/view?usp=sharing")' >Einkaufsliste herunterladen</button>
  </div>
  </div>
</div>
<div class="w-100" style="height:530px" id="iframeContainer"></div>








