import {Component, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from '../services/auth.service';
import { User } from "../shared/services/user";
import { Community } from "../shared/services/community";
import { LocalStorage } from '@ngx-pwa/local-storage';
import { CommunityService } from '../services/community.service';


import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {combineLatest} from 'rxjs';

import {concatMap, map, switchMap} from 'rxjs/operators';


@Component({
  selector: 'app-community-overview',
  templateUrl: './community-overview.component.html',
  styleUrls: ['./community-overview.component.css']
})
export class CommunityOverviewComponent implements OnInit {
  loginVal:boolean;
  userObj: User;
  communityid: any;
  communityObj: Community;
  userDataToAdd: boolean = false;
  userVerified: boolean = false;
  communityName: string = "";
  communityList: any[];
  mailVerified: boolean = false;
uid: any;
ready: number =0;
subscription: any;
subscription1: any;
mailsent: boolean=false;
currenturl: any;

  constructor(private auth: AuthService, private router: Router,private com: CommunityService) {
      this.currenturl = window.location.href;
     this.subscription =   this.auth.getUser();
    this.subscription1 = this.com.getAllCommunities();
    if(this.auth.isLoggedIn){
    this.subscription.pipe(
        concatMap(val=> {
          const userData= val;
          return combineLatest([this.subscription1]).pipe(map(commData=>({userData,commData})))
        })).subscribe(({userData, commData}) =>{
// Datenabruf fertig
        this.userObj = userData;
        this.userVerified = this.userObj.verifiedProfil;
        this.mailVerified = this.userObj.emailVerified;
        this.mailVerified = true;
        this.uid = userData["uid"];
        this.communityid = userData["communityId"];
        console.log(userData);
        if (this.communityid && this.mailVerified) {
              this.router.navigate(['admin']);
        }
        else{
          this.getAllCommunities(commData[0]);
        }
        this.ready = 1;

      });}






   // this.getAllCommunities();
  }

  onSubmit(userForm){
    this.userObj.verifiedProfil = true;
  console.log(this.userObj);
  this.userVerified = true;
  this.auth.updateUser(this.userObj);
//location.reload();
  }

  getAllCommunities(data){

              this.communityList = data.map(e => {
                                          return {
                                            id: e.payload.doc.id,
                                            ...e.payload.doc.data( )as Community } ;
                                          })

}

sendVerificationMail(){
    this.auth.SendVerificationMail();
    this.mailsent= true;

}

enterCommunity(id:number) {
    console.log(id);
  if (id) {
    this.com.addToCommunity(this.userObj.uid, id);
  }
    this.router.navigate(['admin']).then(() => {
  //  location.reload();

  });
}


  createCommunity(titel){
    this.communityObj = ({
      titel : titel,
      admin : this.userObj.uid,
      uid: ""
    } as Community );
    console.log("coverview");
    this.com.createCommunity(this.communityObj);
     this.router.navigate(['admin']);
  }


  ngOnInit(): void {


    this.userObj = ({
      uid: this.uid,
      firstname : "Vorname",
      lastname : "Nachname",
      communityId: "",
    } as User );
  }



}
