


<script src="https://platform.fatsecret.com/js?key=519fc0cc01c84a12a8874863eedd2ce9&auto_load=true"></script>
<ng-template #keinartikel>
  <div class="text-center">Es befinden sich keine Artikel auf deinem Einkaufszettel</div>
</ng-template>
<ng-template id="mymodal" #mymodal let-modal>

  <div class="container pl-0 pr-0">
    <div class="modal-custom">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Einkaufszettel</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row pl-2 w-100" #einkaufszettel_display>
          <div class="w-100" *ngIf="badge_counter; else keinartikel ">
            <div class="row pb-3">
              <div class="col">
                <label>Benenne deinen Einkaufszettel:</label>
                <input type="text" #zettelName require class="form-control" placeholder="Einkaufszettel Name..."
                       value="" required>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col">
                <label>Bis wann benötigst du deinen Einkauf?:</label>
                <input type="date" #zieldatum require class="form-control" placeholder="Zieldatum" value="" required>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col">
                <label style="width: 21rem">Für wen ist der Einkauf?:</label>
                <input type="radio" #mich value="mich" name="zielperson" checked="true"
                       (change)="changezielperson($event, mich.checked, 'mich')"> Mich
                <input type="radio" #andere value="fremd" name="zielperson"
                       (change)="changezielperson($event, andere.checked, 'andere')"> Andere Person

              </div>
            </div>
            <div class="row pb-3" *ngIf="this.zielperson=='andere'">
              <div class="col">
                <label>Bitte Daten der Zielperson eingeben:</label><br>
                <input type="text" #nameandere class="form-control" placeholder="Vorname & Name" name="gender"
                       (change)="changeandere($event, 'name', nameandere.value)"><br>
                <input type="text" #strasse class="form-control" placeholder="Straße & Hausnummer" name="gender"
                       (change)="changeandere($event, 'strasse', strasse.value)"><br>
                <input type="text" #plzortandere class="form-control" placeholder="PLZ & Ort" name="gender"
                       (change)="changeandere($event, 'plzort', plzortandere.value)"><br>
                <input type="text" #telefonandere class="form-control" placeholder="Telefonnummer" name="gender"
                       (change)="changeandere($event, 'telefon', telefonandere.value)">

              </div>
            </div>
            <div class="row">
              <div class="col font-weight-bold">Artikelliste:</div>
            </div>

            <div class="row pt-3 pl-3 container-fluid">


              <div class="mt-3 p-3 pt-4 pb-3 pl-2 w-100" style="background-color:#00000026" *ngFor="let item of einkaufszettel">

                <div class="pl-2 pt-2 row align-items-center">
                  <div clas="col-1"><span class="pr-2 font-weight-bold">Menge:</span></div>
                  <div class="col-4">

                    <input type="number" style="font-size:10px" #menge class="form-control  formrespmenge"
                           placeholder=""
                           value="{{item?.menge}}" (change)="item.menge=menge.value" required>
                  </div>

                </div>
                <div class="pl-2 pt-2  row align-items-center">
                  <div clas="col-1">
                    <span class="pr-2 font-weight-bold">Einheit:</span></div>
                  <div class="col-5">
                    <select style="font-size:10px" class="form-control  formrespmenge" value="{{item?.einheit}}"
                            (change)="item.einheit=einheit2.value" #einheit2 require>
                      <option value="Stück">Stück</option>
                      <option value="kg">kg</option>
                      <option value="g">g</option>
                      <option value="ml">ml</option>
                      <option value="Liter">Liter</option>
                      <option value="Packung">Packung</option>
                      <option value="Kasten">Kasten</option>
                    </select>
                  </div>
                </div>

                <div class=" pl-2  row"><span class=" pr-2 font-weight-bold">Bezeichnung: </span>
                  {{item?.name}}
                </div>
               <div class="pl-2  row"><span class=" pr-2 font-weight-bold">Bemerkung: </span>
                </div>
                <div class="pl-2  row">
                  <div class="col m-2"><input type="text" #bemerkung class="form-control formrespmenge"
                                              placeholder="Menge"
                                              value=" {{item?.bemerkung}}"
                                              (change)="item.bemerkung=bemerkung.value" required>
                  </div>
                </div>
                <div *ngIf="item.image_url" class="row justify-content-center">
                  <img src="{{item?.image_url}}" alt="Kein Produktbild verfügbar"
                       class="img-thumbnail-p">
                </div>
                <div class="row justify-content-end">
                  <div class="mr-5 mb-2" #deletebutton (click)="deleteArtikel(item)"><i
                    class="fas fa-trash-alt"></i></div>
                </div>
              </div>

              <div class="row pl-3 pt-2 w-100">
                <label> Bemerkung: </label></div>
              <div class="row pl-3 w-100">
                <div class="col"><textarea #bemerkung class="form-control"
                                           placeholder="Was soll beim Einkauf beachtet werden" value=""
                                           required></textarea></div>
              </div>


              <div class="row pl-3 pt-4 w-100">
                <input type="submit" class="btn-responsive btn btn-primary btn-lg btn-bloc"
                       value="Einkaufsezttel abschicken"
                       (click)="saveEinkaufszettel(bemerkung.value, zieldatum.value, zettelName.value); modal.close('Save click');">
              </div>

              <div class="row pt-3"></div>
            </div>
          </div>


        </div>


      </div>
    </div>
  </div>
</ng-template>

<ng-template id="artikelpopup" #artikelpopup let-modal>

  <div class="container pl-0 pr-0" style="background-color:grey">
    <div class="modal-custom">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Artikel hinzufügen</h4>
        <button type="button" #button class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>

      <div class="modal-body modresp m-3">

        <br>
        <div class="row">
          <div class="col-6">
            Name
          </div>

          <div class="col-3">
            Menge
          </div>
          <div class="col-3">
            Einheit
          </div>
        </div>
        <div class="form-row">
          <div class="col-6">
            {{currentArtikel.product_name}} {{currentArtikel.brands}}
          </div>
          <div class="col-3">
            <input type="number" #menge class="form-control formresp" placeholder="Menge" value="1" required>
          </div>
          <div class="col-3">
            <select class="form-control formresp formrespmenge" #einheit>
              <option value="Stück">Stück</option>
              <option value="kg">kg</option>
              <option value="g">g</option>
              <option value="ml">ml</option>
              <option value="Liter">Liter</option>
              <option value="Packung">Packung</option>
              <option value="Kasten">Kasten</option>
            </select>
          </div>

        </div>
        <div class="row justify-content-center pt-3 pb-3">
          <img src={{currentArtikel?.image_url}} class="img-fluid">
        </div>

        <div class="row"><label> Bemerkung </label></div>
        <div class="row">
          <textarea #bemerkung class="form-control" placeholder="Was soll beim diesem Artikel beachtet werden?" value=""
                    required></textarea>
        </div>
        <div class="row    justify-content-center text-right pt-4" style="justify-content: flex-end;">
          <input #addbutton type="submit" class="w-50 btn btn-primary btn-lg btn-block btn-responsive"
                 value="Artikel hinzufügen"
                 (click)="addApiArtikelToEinkaufszettel(this.currentArtikel.code,'', this.currentArtikel.brands, this.currentArtikel.product_name+' '+this.currentArtikel.brands, this.currentArtikel.image_url, this.currentArtikel.categories, badgeanimation, bemerkung.value, menge.value, einheit.value )">


        </div>
      </div>


    </div>
  </div>


</ng-template>
<ng-template #ean_suche id="ean_suche" let-modal>
  <div class="container pl-0 pr-0">
    <div class="modal-custom">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Artikel hinzufügen</h4>
        <button type="button" #button class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div #eanbereich class="row pb-1">
          <div #ean id="benutzerdefiniert" class="col">
            <label>EAN-Code manuell eingeben:</label>
            <input type="text" #eantext require class="form-control" placeholder="EAN-Code..." value="" required><br>
            <input type="submit" class="btn btn-primary btn-lg btn-bloc"
                   value="EAN-Suche"
                   (click)="getEanArticle(eantext.value)">
            <br>
            <div *ngIf="ean_detection">
              <span style="color:green"><i class="fas fa-check-circle"></i></span> EAN-Code wurde erkannt: {{eancode}} <br>

            </div>
            <div *ngIf="database_found==false">
              <span style="color:red"><i class="fas fa-exclamation-circle"></i></span> Artikel wurde leider nicht in der Datenbank gefunden<br>
            </div>
            <div *ngIf="database_found==true">
              <span style="color:green"> <i class="fas fa-check-circle"></i></span> Artikel in der Datenbank gefunden <br>
            </div>
            <div *ngIf="ean_search==true" class="row text-center  justify-content-md-center">
              <div class="col-md-4 p-1 justify-content-md-center">
                <div style="justify-content: center;" class=" h-50 row  justify-content-md-center"><img
                  style="max-width: 100%; max-height: 100%  " class="img-fluid" src="{{eanartikel.image_small_url}}">
                </div>
                <div style="justify-content: center;"
                     class="h-25 row text-center justify-content-md-center"> {{eanartikel.brands}}
                  <br> {{eanartikel.product_name}} </div>
                <div style="justify-content: center;" class="row text-center justify-content-md-center"><input
                  (mouseenter)="change($event, true)" (mouseout)="change($event, false)" #addbutton type="submit"
                  class="btn btn-primary btn-lg btn-bloc"
                  value="+"
                  (click)="open(artikelpopup);currentArtikel=eanartikel">
                </div>
              </div>

            </div>
            <label class="pt-3 pb-1">Vorhandenen Artikel einscannen:</label>
            <div id="interactive" class="viewport">
              <!-- QuaggaJS will populate this element -->
            </div>


          </div>

        </div>
      </div>
    </div>
  </div>

</ng-template>
<div class="container">
<div style="z-index: 0; margin-top: -2rem;
    margin-left: 12rem;"class="sticky-top" #badgeanimation (click)="badge_display_f(true);open(mymodal)">
  <i class="fa " style="font-size:3vh;">&#xf07a;</i>
  <span class='badge badge-warning' id='lblCartCount'>{{badge_counter}} </span>

</div>
</div>
<div class="container">


<div class="row justify-content-center pt-3">
  <h4 style="" class="pt-2 text-center">Einkaufszettel anlegen </h4>
</div>
  <div class="pl-3">



    <div #benutzerdefiniert id="benutzerdefiniert">
      <div class="row pt-1"><label>Benutzerdefinierten Artikel hinzufügen:</label></div>
      <div class="row pb-2">
        <input type="text" #eigenArtikel require class="form-control w-75"
                                   placeholder="Artikelname eingeben..." value="" required></div>


      <div  (click)="addArtikelEigen(eigenArtikel.value, '', badgeanimation, eigenArtikel)" class="row btn btn-primary btn-lg btn-bloc"><i class="fas fa-plus"></i> Artikel hinzufügen</div>

      <div class="row pt-4 pb-2"><label>Artikel mittels Barcode(EAN) hinzufügen:</label></div>
      <div  (click)="open(ean_suche);createScanner()" class="row btn btn-primary btn-lg btn-bloc"><i class="fas fa-barcode"></i> Barcode-Scanner starten</div>

      <div style="display:none" class="row pb-2"><label>Suche nach Artikeln: &nbsp; <small> (Beta) </small></label></div>
      <div style="display:none" class="row pb-2"><input type="text" #artikelsuche require class="form-control w-75"
                                   placeholder="Suchbegriff eingeben..." value=""
                                   required></div>
      <div style="display:none" class="row pb-2">
        <input type="submit" class="btn btn-primary btn-lg btn-bloc"
               value="Suchen"
               (click)="getArtikel(artikelsuche.value, '1')"></div>
    </div>
    <div #analogZettel id="analogZettel">
      <div class="row pb-2 mt-3"><label>Einkaufszettel als Foto hochladen:</label></div>

      <div  (click)="showAnalogProzess()" class="row btn btn-primary btn-lg btn-bloc"><i class="fas fa-camera"></i> Foto hochladen</div>

      <div *ngIf="prozessAnalog">

      <div class="modal-body">
          <div class="w-100">

          <div *ngIf="showAnalogForm">
            <div class="row pb-3">
              <div class="col">
                <label>Benenne deinen Einkaufszettel:</label>
                <input type="text" #zettelName require class="form-control" placeholder="Einkaufszettel Name..."
                       value="" required>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col">
                <label>Bis wann benötigst du deinen Einkauf?:</label>
                <input type="date" #zieldatum require class="form-control" placeholder="Zieldatum" value="" required>
              </div>
            </div>
            <div class="row pb-3">
              <div class="col">
                <label>Für wen ist der Einkauf?:</label>
                <input type="radio" #mich value="mich" name="zielperson" checked="true"
                       (change)="changezielperson($event, mich.checked, 'mich')"> Mich
                <input type="radio" #andere value="fremd" name="zielperson"
                       (change)="changezielperson($event, andere.checked, 'andere')"> Andere Person

              </div>
            </div>
            <div class="row pb-3" *ngIf="this.zielperson=='andere'">
              <div class="col">
                <label>Bitte Daten der Zielperson eingeben:</label><br>
                <input type="text" #nameandere class="form-control" placeholder="Vorname & Name" name="gender"
                       (change)="changeandere($event, 'name', nameandere.value)"><br>
                <input type="text" #strasse class="form-control" placeholder="Straße & Hausnummer" name="gender"
                       (change)="changeandere($event, 'strasse', strasse.value)"><br>
                <input type="text" #plzortandere class="form-control" placeholder="PLZ & Ort" name="gender"
                       (change)="changeandere($event, 'plzort', plzortandere.value)"><br>
                <input type="text" #telefonandere class="form-control" placeholder="Telefonnummer" name="gender"
                       (change)="changeandere($event, 'telefon', telefonandere.value)">

              </div>
            </div>
            <div class="row pl-3 pt-2 w-100">
              <label> Bemerkung: </label></div>
            <div class="row pl-3 w-100">
             <textarea #bemerkung class="form-control"
                                         placeholder="Was soll beim Einkauf beachtet werden" value=""
                                         required></textarea>
            </div>
            <div class="row pl-3 pt-2 w-100">
              <button class="btn btn-primary"  (click)="addPhotoToGallery(bemerkung.value, zieldatum.value, zettelName.value)">Zettel fotografieren und abschicken</button>
            </div>
            </div>
            <div *ngIf="loaderAnalog">
              <img src="assets/img/ci_loader.gif">
            </div>
            <div *ngIf="successAnalog">
              Einkaufszettel wurde erfolgreich hochgeladen.
            </div>
              </div>
      </div>
    <div class="row text-center  justify-content-md-center">



  </div>
</div>


<div class="pt-5"></div>






      </div>
  </div>
    <div class="row text-center  justify-content-md-center">

      <div class="col-md-4 p-1 justify-content-md-center" *ngFor="let artikels of artikel">

        <div style="justify-content: center;" class=" h-50 row  justify-content-md-center"><img
          style="max-width: 100%; max-height: 100%  " class="img-fluid" src="{{artikels.image_url}}"></div>
        <div style="justify-content: center;"
             class="row text-center justify-content-md-center">{{artikels.name}}
        </div>
        <div style="justify-content: center;" class="row text-center justify-content-md-center"><input
          (mouseenter)="change($event, true)" (mouseout)="change($event, false)" #addbutton type="submit"
          class="btn btn-primary btn-lg btn-bloc"
          value="+"
          (click)="open(artikelpopup);currentArtikel=artikels">
        </div>

      </div>
    </div>
      <div class="row text-center  justify-content-md-center">
        <div class="pt-4 text-center" *ngIf="pages>=1">
          <p>Seite {{pages_counter}} von {{pages.toFixed(0)}}</p>
          <input type="submit" class="btn btn-primary btn-lg btn-bloc btn-responsive"
                 value="vorherige Seite <" *ngIf="pages_counter>1"
                 (click)="getArtikel(artikelsuche.value, pagesCounterback())">
          <span class="p-2">   </span>
          <input type="submit" class="btn btn-primary btn-lg btn-bloc btn-responsive"
                 value="> Nächste Seite" *ngIf="pages>1"
                 (click)="getArtikel(artikelsuche.value, pagesCounter())">

        </div>
      </div>
    </div>
