

<div id="navbarprint" class="w-100">
  <nav class="navbar navbar-expand-lg bg-secondary text-uppercase " id="mainNav">
    <div class="container">
      <a class="navbar-brand js-scroll-trigger" href="#page-top">#Besorg'sElfriede</a>
      <button class="navbar-toggler navbar-toggler-right text-uppercase font-weight-bold bg-primary text-white rounded"
              type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive"
              aria-expanded="false" aria-label="Toggle navigation">Menu <i class="fas fa-bars"></i></button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                               [routerLink]="['/about']">About Us</a></li>
          <li class="nav-item mx-0 mx-lg-1"><a class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger"
                                               [routerLink]="['/faq']">FAQ</a></li>
          <li *ngIf="(auth.isLoggedIn  == false)" class="nav-item mx-0 mx-lg-1"><a
            class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" [routerLink]="['/login']">Login</a></li>
          <li *ngIf="(auth.isLoggedIn == true)" class="nav-item mx-0 mx-lg-1"><a
            class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" [routerLink]="['/admin']">Übersicht</a>
          </li>
          <li *ngIf="(auth.isLoggedIn == true)" class="nav-item mx-0 mx-lg-1"><a
            class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" (click)="logout()">Logout</a></li>
          <li *ngIf="(auth.isLoggedIn  == false)" class="nav-item mx-0 mx-lg-1"><a
            class="nav-link py-3 px-0 px-lg-3 rounded js-scroll-trigger" [routerLink]="['/register']">Registrieren</a></li>


        </ul>
      </div>
    </div>
  </nav>
  <div *ngIf="ready">
    <div  class="container">
      <div class="row pt-4">
        <div class="col-1">
          <div (click)=goBack()><i class="fas fa-arrow-left fa-2x"></i></div>
        </div>
        <div class="col-6">
          <a [routerLink]="['/admin']"><i class="fas fa-home fa-2x"></i></a>
        </div>

        </div>



      </div>
    </div>
  </div>
