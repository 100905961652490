import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-take-shopping',
  templateUrl: './take-shopping.component.html',
  styleUrls: ['./take-shopping.component.css']
})
export class TakeShoppingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
