<div class="container">
  <div class="col-md-6 offset-md-3 text-center" style="margin-top:5px">
    <div class="card-body">
      <h1 style="font-weight: bold; color:#A4DD5F; font-size: 2rem;" >Autorisierung </h1><br>
      <img style="width: 62%; margin-top:-12%" src="https://m.media-amazon.com/images/G/01/mobile-apps/dex/alexa/auto/Auto-Website/v2/alexa-logo-white-new._CB1547615241_.png">
      <form>

<div class="row pl-3"> Amazon Alexa kann: </div>
          <div class="row pl-3"><i style="color:#A4DD5F" class="fas fa-check-circle"></i>Einkaufszettel anlegen</div>
          <div class="row pl-3 pb-3"><i style="color:#A4DD5F" class="fas fa-check-circle"></i>Einkaufszettel auslesen</div>

        <div class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-user"></i></span>
          </div>
          <input type="text" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Benutzer">

        </div>
        <div class="input-group form-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-key"></i></span>
          </div>
          <input type="password"  [(ngModel)]="password"  [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Passwort">
        </div>
        <div class="form-group">
          <button type="submit" value="Login" (click)="login()" class="btn btn-primary btn-lg btn-block">Jetzt Authorisieren</button>
        </div>
      </form>
      <div class="formGroup">
        <button type="button" class="btn btn-primary btn-lg btn-block" style="vertical-align: middle;" (click)="auth.GoogleAuth()" >
          <i class="fab fa-google-plus-g fa-1x"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-6 offset-md-3 text-center" style="margin-top:5px">
    <div class="d-flex justify-content-center links">
      Noch kein Helfer? <a href="/register">Jetzt registrieren</a>
    </div>
    <div class="d-flex justify-content-center">
      <a href="forgotPassword">Passwort vergessen?</a>
    </div>
  </div>
</div>
