<body>
<div class="container pt-4 pb-5">
  <div class="row">
    <div class="col-md-12">
      <div class="section-title text-center wow zoomIn">
        <h1>FAQ - Frequently Asked Questions</h1>
        <span></span>
        <p>Unsere am häufigsten gestellten Fragen</p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingOne">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                1.	Was ist Besorg's Elfriede eigentlich?
              </a>
            </h4>
          </div>
          <div id="collapseOne" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
            <div class="panel-body black" >
              <p>Wir sind eine Plattform, welche Hilfesuchende und Helfer zueinandern bringt.
                Unser großer Vorteil liegt darin, eine fließenden Übergang zwischen der digitalen und der analogen Welt zu haben. Egal ob du Hilfe beim Einkaufen brauchst oder gerne für jemanden in deiner Umgebung den Einkauf erledigen willst, bei uns findest du was du suchst. Lade einfach einen Einkaufszettel hoch oder nimm einen aus der Umgebung an und schon kann es losgehen. Helfen war nie einfacher!
              </p>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingTwo">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                2.	Wie funktioniert das Ganze?
              </a>
            </h4>
          </div>
          <div id="collapseTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo">
            <div class="panel-body">
              <p>Im Grunde es ist Besorg's Elfriede äußerst simpel. Ihr registriert euch, gebt euren Standort frei und stellt entweder einen Einkaufszettel in das Portal (über die Homepage, einen analogen Einkaufszettel oder der App als Hilfesuchender) oder wählt über die genannten Möglichkeiten einen offenen Einkaufszettel aus. Nun setzt ihr euch mit dem Gegenüber in Verbindung und vereinbart einen Übergabetermin und die Möglichkeit der Bezahlung. Das wars!</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingThree">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                3.	Brauche ich bestimmte Vorraussetzungen um mich registieren zu können?
              </a>
            </h4>
          </div>
          <div id="collapseThree" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree">
            <div class="panel-body">
              <p>Nein. Bei uns kann und soll jeder mitmachen. Egal ob Mann oder Frau, jung oder alt.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingFour">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                4.	Ist die Registration und Teilnahme kostenlos?
              </a>
            </h4>
          </div>
          <div id="collapseFour" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour">
            <div class="panel-body">
              <p>Natürlich. Besorg's Elfriede ist zu 100% kostenfrei.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingFive">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                5.	Wie kann ich jemanden aus meiner Umgebung helfen?
              </a>
            </h4>
          </div>
          <div id="collapseFive" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive">
            <div class="panel-body">
              <p>Registriere dich auf dem Portal und gib deinen Standort frei. Im Handumdrehen siehst du, ob jemand in deinem festgelegten Suchradius Hilfe benötigt. Nun musst du nur noch den entpsrechenden Einkaufszettel annehmen und schon kanns losgehen.</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingSix">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                6.	Kann ich mich auch nur als Hilfesuchender registrieren?
              </a>
            </h4>
          </div>
          <div id="collapseSix" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSix">
            <div class="panel-body">
              <p>DU kannst nicht nur, du SOLLST es sogar tun :)</p>
            </div>
          </div>
        </div>
        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingSeven">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                7.	Brauche ich zwingend einen PC oder ein Smartphone?
              </a>
            </h4>
          </div>
          <div id="collapseSeven" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven">
            <div class="panel-body">
              <p>	Nein, möchtest du dich allerdings als Helfer beteiligen, ist eine Registrierung auf der 	Homepage erforderlich. Ebenfalls musst du die offenen Einkaufszettel über die 	Internetseite  beziehungsweise über die App annehmen.</p>
            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingSevenTwo">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseSevenTwo" aria-expanded="false" aria-controls="collapseSevenTwo">
                8.	Gibt es eine App?
              </a>
            </h4>
          </div>
          <div id="collapseSevenTwo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingSevenTwo">
            <div class="panel-body">
              <p> Grundsätzlich hat eine App die gleichen Funktionalitäten wie die Browser-Version von Besorgs Elfriede. Um aber Funktionalitäten wie Push-Benachrichtigungen nutzen zu können, braucht es eine App, deshlab  sind wir gerade dabei eine App für iOS und Android zu entwickeln. Durch die Nutzung der Browser-Version hast du jedoch keinerlei Nachteile oder Beeinträchtigungen.
              Um immer Up-To-Date zu sein kannst du dich z.B. per E-Mail über neue Einkaufszettel in deiner Nähe benachrichtigen lassen.
              </p>
            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingEight">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                9.	Wie funktioniert das mit der Bezahlung?
              </a>
            </h4>
          </div>
          <div id="collapseEight" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight">
            <div class="panel-body">
              <p>Das bleibt ganz euch überlassen. Zum jetzigen Zeitpunkt findet die Bezahlung außerhalb 	unseres Systems statt. Ihr könnt euch also mit eurem Gegenüber in Verbindung setzen und 	die Zahlungsabwicklung bequem besprechen.</p>
            </div>
          </div>
        </div>

        <div class="panel panel-default">
          <div class="panel-heading" role="tab" id="headingNine">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                10.	Noch fragen?
              </a>
            </h4>
          </div>
          <div id="collapseNine" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingNine">
            <div class="panel-body black">
              <p>Du hast noch Fragen, die bislang unbeantwortet blieben? Sende uns doch einfach	einen kurzen Text mit deinem Anliegen an <a  style="color: #A4DD5F" href="mailto:info@besorgselfriede.org?Subject=Frage - Support&body=Hallo Besorgs-Elfriede-Team,%0D%0A %0D%0Ahier meine Frage:%0D
         " target="_top">info@besorgselfriede.org</a> oder auf unseren Sozialen-Kanälen auf Instagram und Facebook. Wir versuchen uns so schnell wie möglich mit der in 	Verbindung zu setzen.</p>
            </div>
          </div>
        </div>

      </div>
    </div><!--- END COL -->
  </div><!--- END ROW -->
</div>
</body>
