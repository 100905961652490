import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  Plugins,
  PushNotification,
  ActionSheetOptionStyle,
  PushNotificationToken,
  PushNotificationActionPerformed, Capacitor,
} from '@capacitor/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {AuthService} from './services/auth.service';
import {not} from 'rxjs/internal-compatibility';

const { PushNotifications } = Plugins;
const { Modals } = Plugins;
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'besorgselfriede';

  constructor(public auth: AuthService, public router: Router) {


  }
  async showConfirm(notification) {
    console.log("das ist die url"+ notification.data.url);
    const confirmRet = await Modals.confirm({
      title: notification.title,
      message: notification.body+"\n \nMöchtest du dir den Einkaufszettel ansehen?",
      okButtonTitle: "Ja",
      cancelButtonTitle: "Nein, zurück"
    })

if(confirmRet.value){
    this.router.navigate([notification.data.routeto],{ queryParams: { id: notification.data.id }});
}
     // this.router.navigate([notification.data.url.toString()]);
    //    this.router.navigate([notification.data.url]);

  }

  ngOnInit() {

    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
    console.log('Initializing HomePage');
    if (isPushNotificationsAvailable) {
      // Request permission to use push notifications
      // iOS will prompt user and return if they granted permission or not
      // Android will just grant without prompting
      // On success, we should be able to receive notifications
      let thisreference = this;
      PushNotifications.addListener('registration',
        (token: PushNotificationToken) => {
        thisreference.auth.SetNotificationKey(token.value)
        //  alert('Push registration success, token: ' + token.value);
        }
      );

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError',
        (error: any) => {
        //alert("error"+JSON.stringify(error))
          console.log('Error on registration: ' + JSON.stringify(error));
        }
      );

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener('pushNotificationReceived',
        async (notification: PushNotification) => {
        //alert('Push received: ' + JSON.stringify(notification));
          console.log('Push received: ' + JSON.stringify(notification));
        let  test = await this.showConfirm(notification);
        }
      );

      // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        async (notification: any) => {
      console.log("hier start");
       // alert('Push action performed: ' + JSON.stringify(notification))
          console.log('Push action performed: ' + JSON.stringify(notification));
          console.log("jawoll_soweit");
          console.log("routeto", notification.notification.data.routeto);
          await this.router.navigate([notification.notification.data.routeto],{ queryParams: { id: notification.notification.data.id }});
        }
      );


    }
  }
}
