<div id="footer" class="w-100 text-center">

  <div class="container">
    <div class="content row">
      <!-- Footer Location-->
      <div class="rnd col-lg-4 mb-5 mb-lg-0">
        <h4 class="text-uppercase mb-2">Location</h4>
        <p class="lead mb-0">Raum Ingolstadt</p>
      </div>
      <!-- Footer Social Icons-->
      <div class="col-lg-4 mb-5 mb-lg-0">
        <h4 class="rnd text-uppercase mb-2">Infos & Aktuelles</h4>
        <a class="btn btn-outline-light btn-social mx-1" href="https://www.facebook.com/BesorgsElfriede"><fa-icon [icon]="faFacebook"></fa-icon></a>
        <a class="btn btn-outline-light btn-social mx-1" href="https://www.instagram.com/besorgs_elfriede/?hl=de"><fa-icon [icon]="faInstagram"></fa-icon></a>
        <a class="btn btn-outline-light btn-social mx-1 d-none " href="#"><fa-icon [icon]="faGooglePlay"></fa-icon></a>
        <a class="btn btn-outline-light btn-social mx-1 d-none" href="#"><fa-icon [icon]="faAppStore"></fa-icon></a>
      </div>
      <!-- Footer About Text-->
      <div class="rnd col-lg-4">
        <h4 class="text-uppercase mb-2">Über uns</h4>
        <p class="lead mb-0">Member of <a href="http://sprint4local.de">Sprint4Local</a></p>

      </div>




  </div>
    <div class="bottom"><a class="imp" href="/impressum"> Impressum</a><small> | </small> <a class="imp" href="/datenschutz"> Datenschutzbedingungen</a><small> | Copyright © BesorgsElfriede 2020 </small></div>
</div>

</div>
<!-- Copyright Section-->
