<div class="container pt-3 pb-4">
  <p class="masthead-heading"><strong><p class="bigger">Datenschutzerklärung</p></strong></p>
  <h5 class="font-weight-bolder pt-2 pb-2">Im Zuge des Hackathon "sprint4local" wurde diese Website vom Team "BesorgsElfriede" erstellt.</h5>
  <p>Das Team besteht aus folgenden Mitgliedern:</p>
  <ul>
    <li>Dennis Fuchs</li>
    <li>Florian Gayring</li>
    <li>Andreas Winkler</li>
    <li>Stefan Winkler</li>

  </ul>
  <p><strong>Allgemeiner Hinweis und Pflichtinformationen</strong></p>
  <p><strong>Benennung der verantwortlichen Stelle</strong></p>
  <p>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</p>
  <p><span id="s3-t-firma">Team BesorgsElfriede</span><br><span id="s3-t-strasse">Forststr. 12a</span><br><span id="s3-t-plz">93336</span> <span id="s3-t-ort"> Steinsdorf</span></p><p></p>
  <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>

  <p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p>
  <p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>

  <p><strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong></p>
  <p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html" target="_blank">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</p>

  <p><strong>Recht auf Datenübertragbarkeit</strong></p>
  <p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</p>

  <p><strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong></p>
  <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten, deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten Kontaktmöglichkeiten an uns wenden.</p>

  <p><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
  <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in der Browserzeile.</p>

  <p><strong>Server-Log-Dateien</strong></p>
  <p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
  <ul>
    <li>Besuchte Seite auf unserer Domain</li>
    <li>Datum und Uhrzeit der Serveranfrage</li>
    <li>Browsertyp und Browserversion</li>
    <li>Verwendetes Betriebssystem</li>
    <li>Referrer URL</li>
    <li>Hostname des zugreifenden Rechners</li>
    <li>IP-Adresse</li>
  </ul>
  <p>Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>

  <p><strong>Registrierung auf dieser Website</strong></p>
  <p>Zur Nutzung bestimmter Funktionen können Sie sich auf unserer Website registrieren. Die übermittelten Daten dienen ausschließlich zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes. Bei der Registrierung abgefragte Pflichtangaben sind vollständig anzugeben. Andernfalls werden wir die Registrierung ablehnen.</p>
  <p>Im Falle wichtiger Änderungen, etwa aus technischen Gründen, informieren wir Sie per E-Mail. Die E-Mail wird an die Adresse versendet, die bei der Registrierung angegeben wurde.</p>
  <p>Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</p>
  <p>Wir speichern die bei der Registrierung erfassten Daten während des Zeitraums, den Sie auf unserer Website registriert sind. Ihren Daten werden gelöscht, sollten Sie Ihre Registrierung aufheben. Gesetzliche Aufbewahrungsfristen bleiben unberührt.</p>

  <p><strong>Veröffentlichung personenbezogener Daten</strong></p>
  <p>Beim erstellen von Einkaufszetteln werden personenbezogene Daten erfasst und veröffentlicht.</p>
<p>Mit dem akzeptieren der AGBs sowie dieser Datenschutzbestimmungen stimmen sie der Veröffentlichung ihrer Daten auf der BesorgsElfriede Plattform im Zuge der Erbringung von Dienstleistungen in der Form des Erledigens von Einkäufens zu.</p>
  <p>Selbstverständlich werden nur die Daten veröffentlicht die auch dazu notwendig sind:</p>
  <p>- Vorname, Nachname, Straße, PLZ, Ort, Handynummer, Email</p>
  <p>- Personenbezogene Daten die aus Einkaufszetteln hervorgehen</p>
  <p>Wenn Einkaufszettel für Dritte erstellt werden, ist der Ersteller dafür verantwortlich, dass Dritte mit der Veröffentlichung ihrer persönlichen Daten einverstanden sind.</p>


  <p><strong>Mail-Benachrichtigungen</strong></p>
  <p>Mit dem akzeptieren der AGBs sowie dieser Datenschutzbestimmungen stimmen sie der automatischen Aktivierung der Mail-Benachrichtigungs-Funktion zu </p>
  <p>Diese Funktion informiert sie per Mail über neue Geschehnisse in der Plattform</p>
  <p>Diese Funkionalität kann jederzeit unter Profil & Einstellungen deaktiviert werden</p>

  <p><strong>Drittanbieter-Verknüpfungen</strong></p>
  <p>Wenn sie ihr BesorgsElfriede-Konto mit Evernote, Amazon Alexa oder Google verknüpfen
  hat dieser Anbieter vollen Zugriff auf ihr Konto und ihre Daten. Die Verantwortung dieser Daten liegt ab diesem Zeitpunkt beim Drittanbieter.
    Ebenso hat BesorgsElfriede Zugriff auf ihre verknüpften Konten. Diese werden nur zum Zwecke des Datenaustausches in Form von digitalen Einkaufszetteln genutzt.
    Es werden keinerlei personenbezogene Daten der Drittanbieter über sie gepseichert oder weitergegeben.

  </p>


  <p><strong>Newsletter-Daten</strong></p>
  <p>Zum Versenden unseres Newsletters benötigen wir von Ihnen eine E-Mail-Adresse. Eine Verifizierung der angegebenen E-Mail-Adresse ist notwendig und der Empfang des Newsletters ist einzuwilligen. Ergänzende Daten werden nicht erhoben oder sind freiwillig.
    Die Verwendung der Daten erfolgt ausschließlich für den Versand des Newsletters.</p>
  <p>Die bei der Newsletteranmeldung gemachten Daten werden ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) verarbeitet. Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose
    Mitteilung per E-Mail oder Sie melden sich über den "Austragen"-Link im Newsletter ab. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.</p>
  <p>Zur Einrichtung des Abonnements eingegebene Daten werden im Falle der Abmeldung gelöscht. Sollten diese Daten für andere Zwecke und an anderer Stelle an uns übermittelt worden sein, verbleiben diese weiterhin bei uns.</p>

  <p><strong>Google Web Fonts</strong></p>
  <p>Unsere Website verwendet Web Fonts von Google. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</p>
  <p>Durch den Einsatz dieser Web Fonts wird es möglich Ihnen die von uns gewünschte Darstellung unserer Website zu präsentieren, unabhängig davon welche Schriften Ihnen lokal zur Verfügung stehen. Dies erfolgt über den Abruf der Google Web Fonts von einem Server von Google in den USA und der damit verbundenen Weitergabe Ihre Daten an Google. Dabei handelt es sich um Ihre IP-Adresse und welche Seite Sie bei uns besucht haben. Der Einsatz von Google Web Fonts erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Als Betreiber dieser Website haben wir ein berechtigtes Interesse an der optimalen Darstellung und Übertragung unseres Webauftritts.</p>
  <p>Das Unternehmen Google ist für das us-europäische Datenschutzübereinkommen "Privacy Shield" zertifiziert. Dieses Datenschutzübereinkommen soll die Einhaltung des in der EU geltenden Datenschutzniveaus gewährleisten.</p>
  <p>Einzelheiten über Google Web Fonts finden Sie unter: <a href="https://www.google.com/fonts#AboutPlace:about">https://www.google.com/fonts#AboutPlace:about</a> und weitere Informationen in den Datenschutzbestimmungen von Google: <a href="https://policies.google.com/privacy/partners?hl=de">https://policies.google.com/privacy/partners?hl=de</a></p>
  <p><small>Quelle: Datenschutz-Konfigurator von <a href="http://www.mein-datenschutzbeauftragter.de" target="_blank">mein-datenschutzbeauftragter.de</a></small></p>





</div>
