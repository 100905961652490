import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
   model: FormObject;
   @ViewChild('MyForm', { static: false }) MyForm: NgForm;

  constructor(public user: AuthService,private router:Router) {

    if(this.user.isLoggedIn == true) {
      this.router.navigate(['communityOverview'])
    }
   }

  ngOnInit(): void {

    this.model = ({} as FormObject );
  }


  submitForm() {

    console.log(this.MyForm.form.valid);
  }

  onSubmit(form) {
  form.value as FormObject;
  if(form.valid){
      console.log(form);
      this.user.SignUp(form.value)
      //this.user.registerUser(form.value)

  }
  console.log(form.value);
}

}

export interface FormObject {
  email: string;
  uid: string;
  password: string;
  firstName: string;
  lastName: string;
  username: string;
  address1: string;
  address2: string;
  zip: number;
  data1: boolean;
  data2: boolean ;

}
