import { BrowserModule } from '@angular/platform-browser';
import { NgModule} from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { CommunityOverviewComponent } from './community-overview/community-overview.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { LandingComponent } from './landing/landing.component';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';

import { AdmindashboardComponent } from './admindashboard/admindashboard.component';

import { UserService } from './services/user.service';
import { CommunityService } from './services/community.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';


import { CommunityDashboardComponent } from './community-dashboard/community-dashboard.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';

import { PrintEinkaufszettelComponent } from './print-einkaufszettel/print-einkaufszettel.component';

import { AddEinkaufszettelComponent } from './add-einkaufszettel/add-einkaufszettel.component';
import { TakeShoppingComponent } from './take-shopping/take-shopping.component';
import { AboutusComponent } from './aboutus/aboutus.component';
import {HttpClientModule} from '@angular/common/http';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MyShoppingTasksComponent } from './my-shopping-tasks/my-shopping-tasks.component';




import { UserComponent } from './user/user.component';
import { AccountLinkingComponent } from './account-linking/account-linking.component';
import { EvernoteComponent } from './evernote/evernote.component';
import {EvernoteService} from './services/evernote.service';
import { EinkaufszettelGlobalComponent } from './einkaufszettel-global/einkaufszettel-global.component';
import {AgmCoreModule} from '@agm/core';
import {GlobalService} from './services/global.service';

import { Plugins, CameraResultType } from '@capacitor/core';
import { FaqComponent } from './faq/faq.component';





@NgModule({
  declarations: [AppComponent,
    LoginComponent,

    RegisterComponent,
    CommunityOverviewComponent,
    NavbarComponent,
    FooterComponent,
    LandingComponent,
    AdmindashboardComponent,
    ForgotPasswordComponent,

    CommunityDashboardComponent,
    ImpressumComponent,
    DatenschutzComponent,

    PrintEinkaufszettelComponent,
    AddEinkaufszettelComponent,
    AboutusComponent,
    MyShoppingTasksComponent,
    UserComponent,
    AccountLinkingComponent,
    EvernoteComponent,
    EinkaufszettelGlobalComponent,
    FaqComponent
     ],
  imports: [

    FontAwesomeModule,
    FormsModule,
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireDatabaseModule,
    NgbModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAWukHJOtC5aln_rbvTvK9NVLWpcLGQRdo',
      libraries: ['places', 'geometry']
    }),
    AgmJsMarkerClustererModule
      ],
  providers: [AngularFirestore, UserService, CommunityService, EvernoteService, GlobalService],
  bootstrap: [AppComponent]
})
export class AppModule { }
