<div class="container">
<div class="row">
  <div class="col-md-12 text-center pt-4">
      <h2>Passwort zurücksetzen</h2>
      <p class="text-center">Nenn uns bitte deine Emailadresse:</p>

      <div class="col-md-6  offset-md-3">
          <input type="text"  #passwordResetEmail require  class="form-control" placeholder="" value="" required>
          <br><br>
          <input type="submit" class="btn btn-primary btn-lg btn-bloc" value="Reset Password" (click)="authService.ForgotPassword(passwordResetEmail.value)">

      </div>

</div>
</div>
