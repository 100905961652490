import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { User } from '../shared/services/user';
import { Community } from '../shared/services/community';
import {combineLatest} from 'rxjs';
import {concatMap, map, switchMap} from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  artikelliste : any[];
  username: string;
  password: string;
  emailVerifiedNote: boolean = false;
  uid: any;
  emailSent:boolean = false;
  subscription: any;
  subscription1: any;
  userObj: User;
  communityid: any;
  communityObj: Community;
  userDataToAdd: boolean = false;
  userVerified: boolean = false;
  communityName: string = "";
  communityList: any[];
  mailVerified: boolean = false;
  ready: number =0;

  constructor(public auth:AuthService, private router:Router) {
    if(this.auth.isLoggedIn){
      this.uid = this.auth.getUserObj().uid;
      if(this.auth.getUserObj().emailVerified){
        this.subscription = this.auth.getUser();
        this.mailVerified = true;
        this.subscription.subscribe(val=>{
          console.log("val",val);
          console.log("vf",this.userVerified);

          if(val.verifiedProfil){
            this.router.navigate(["admin"]);
          }else{
          }

          this.ready=1;
        });



      }else{
        this.emailVerifiedNote = true;
        this.auth.SignOutWithoutForward();
      }
    }


  }

  onSubmit(userForm){
    if(this.userObj.address1 ==' ' || this.userObj.address2 ==' ' ||  this.userObj.zip ==' ' ||  this.userObj.firstname ==' ' ||  this.userObj.lastname ==' ' ||  this.userObj.phone ==' ' || this.userObj.address1 =='' || this.userObj.address2 =='' ||  this.userObj.zip =='' ||  this.userObj.firstname =='' ||  this.userObj.lastname =='' ||  this.userObj.phone =='' || typeof this.userObj.address1 =='undefined' || typeof this.userObj.address2 =='undefined' || typeof this.userObj.zip =='undefined' || typeof this.userObj.firstname =='undefined' || typeof this.userObj.lastname =='undefined' || typeof this.userObj.phone =='undefined'  ) {
        console.log("Daten nicht vollständig");
    }
    else {
      console.log(this.userObj);
      this.userObj.verifiedProfil = true;
      console.log(this.userObj);
      this.userObj.mail_einkauf_erledigt = 1;
      this.userObj.radius = 5000;
      this.userObj.mail_einkaufszettel_zuruecklegen = 1;
      this.userObj.mail_neuer_einkaufszettel = 1;
      this.userObj.mail_statuswechsel_einkaufszettel = 1;
      this.userObj.mail_take_einkaufszettel = 1;
      this.userVerified = true;
      this.auth.updateUser(this.userObj);
//location.reload();

    }
  }



  login(){
    if(this.username && this.password){
      this.auth.SignIn(this.username,this.password).then(e=>{
         if(e.user.uid){
          if(e.user.emailVerified == false){
            this.emailVerifiedNote = true;
            this.auth.SignOutWithoutForward();
          } else{
            window.location.reload();
          }
        }
      }).catch(error => {
       alert(error.message);
      });

    }


  }

  pageReload(){
    window.location.reload();
  }


  ngOnInit(): void {


    this.userObj = ({
      uid: this.uid
    } as User );
  }



  sendVerificationMail(){
    this.auth.SignIn(this.username,this.password).then(e=>{
      this.auth.SendVerificationMail();
      this.emailSent = true;
    }).then(e=>{
      this.auth.SignOutWithoutForward();
    });

  }

}
