<section class="our-webcoderskull padding-lg">
  <div class="container">
    <div class="row heading heading-icon">
      <h2><strong>Unser Team</strong></h2>
    </div>
    <ul class="row">
      <li class="col-12 col-md-6 col-lg-3 ">
        <div class="cnt-block equal-hight bg-black" style="height: 349px;">
          <figure><img src="assets/img/Flo.jpeg" class="img-responsive" alt=""></figure>
          <h3><strong>Flo</strong></h3>
          <p>26 <br>Konzeption, Design & Kommunikation</p>
        </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3 ">
        <div class="cnt-block equal-hight bg-black" style="height: 349px;">
          <figure><img src="assets/img/dennis.jpg" class="img-responsive" alt=""></figure>
          <h3><strong>Dennis</strong></h3>
          <p>29 <br>Konzeption, Design & Kommunikation</p>
        </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3 ">
        <div class="cnt-block equal-hight bg-black" style="height: 349px;">
          <figure><img src="assets/img/andi.jpg" class="img-responsive" alt=""></figure>
          <h3><strong>Andi</strong></h3>
          <p>29 <br> Web Developer <br>Kommunikation</p>
        </div>
      </li>
      <li class="col-12 col-md-6 col-lg-3 ">
        <div class="cnt-block equal-hight bg-black" style="height: 349px;">
          <figure><img src="assets/img/stefan.jpeg" class="img-responsive" alt=""></figure>
          <h3><strong>Stefan</strong></h3>
          <p>23 <br>Web Developer <br>Konzeption</p>

        </div>
      </li>
    </ul>
  </div>
</section>


<section class="our-webcoderskull padding-lg">
  <div class="row heading heading-icon">
    <h2><strong>Unsere Vision</strong></h2>
  </div>
  <div class="container bg-black p-0">

    <ul class="row p-0">
      <li class="col p-0">
        <div class="cnt-block equal-hight  m-0" >
          <figure><img src="https://besorgselfriede.org/assets/img/logomithintergrund.png" class="img-responsive" alt=""></figure>
          <h4 class="">Wir wollen eine gemeinsame Plattform für Jedermann schaffen, in der sowohl <strong>digital natives </strong> als auch <strong>Menschen ohne technischer Affinität</strong> zueinander finden.  Wir sind eine Plattform in der man sich persönlich kennt und die Übergänge zwischen der <strong>analogen</strong> und <strong>digitalen Welt</strong> fließend verlaufen.
            Die Idee zielt auf die Hilfsbereitschaft der Gesellschaft in Verbindung mit <strong>persönlicher Nähe</strong> und Verbundenheit zu den hilfesuchenden Elfriedes in unserer Gegend ab. Somit schaffen wir es auch, die <strong>Netzanonymität</strong> in ein regional bekanntes Umfeld umzuwandeln.
          </h4>
          <img src="assets/img/preistraeger.jpg" class="img-responsive img-normal pt-4" alt="">
        </div>
      </li>


    </ul>
  </div>
</section>


<section class="our-webcoderskull padding-lg last">
  <div class="row heading heading-icon">
    <h2><strong>Unsere Geschichte</strong></h2>
  </div>
  <div class="container bg-black p-0">

    <ul class="row p-0">
      <li class="col p-0">
        <div class="cnt-block equal-hight m-0" >

          <h4 class="">
            Die aktuelle Krise hat uns alle getroffen, den einen mehr, den anderen weniger. Am Ende waren wir aber überrascht, welche Auswirkungen das Corona-Virus für uns alle hat. Was uns aber noch mehr überrascht hat, war die Welle an <strong>Solidarität</strong>, die durch das ganze Land schwappte.
            Jeder Verein, jede Gruppe ja jede noch so kleine Ortsgemeinschaft gründete einen <strong>Helferkreis</strong>, um Menschen in <strong>Not zu unterstützen</strong>. Auch wir gründeten für unsere jeweiligen Heimartorte einen solche Initiative und standen am Ende vor den <strong>gleichen Problemen</strong>: Wie erreichen wir die Menschen? Wie organisiert man die Helfer und die, die Hilfe benötigen? Wie bringen wir digital natives und Menschen ohne Technikaffinität zueinander?
            Während der Challenge <strong>„sprint4local – Eine Woche für Ingolstadt“</strong> erarbeiteten wir Lösungsvorschläge für die einzelnen Fragen und so entstand Schritt für Schritt die Idee zu #besorgselfriede.
            Am Anfang war unser <strong>Erfolg</strong> absolut nicht vorhersehbar und umso <strong>überraschender</strong> war dann die Entscheidung der Jury, unsere Idee als <strong>Sieger zu prämieren</strong>.



          </h4>

        </div>
      </li>


    </ul>
  </div>
</section>




