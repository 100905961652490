import { Injectable, NgZone } from '@angular/core';
import { User } from "../shared/services/user";

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { LocalStorage } from '@ngx-pwa/local-storage';
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed, Capacitor,
} from '@capacitor/core';

const { PushNotifications } = Plugins;
import firebase from 'firebase';
import auth = firebase.auth;
import * as admin from 'firebase-admin';
import * as data from  './besorgselfried-61d78ec4ef30.json';
import {BehaviorSubject} from 'rxjs';
import {GlobalService} from './global.service';
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  userData: any; // Save logged in user data
  userObj: any;
  serviceAccount: any;
  temp: any;
  localStorageUserValue = new BehaviorSubject(this.localStorageUser);
subscriber:any;
  constructor(
    public globalservice: GlobalService,
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone, // NgZone service to remove outside scope warning

  ) {

    this.subscriber = this.afAuth.authState;
    this.subscriber.subscribe(user => {
      if(user){

        this.userData = user;
        this.localStorageUser = JSON.stringify(this.userData);
        const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');
        console.log('Initializing HomePage');
        if (isPushNotificationsAvailable) {
          // Request permission to use push notifications
          // iOS will prompt user and return if they granted permission or not
          // Android will just grant without prompting
          console.log("available jawohl");
          PushNotifications.requestPermission().then(result => {
            if (result.granted) {
              // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();

            } else {
              // Show some error
            }
          }); }

      } else {
        this.localStorageUser = null;
      }
    })


  }



  set localStorageUser(value) {
    this.localStorageUserValue.next(value); // this will make sure to tell every subscriber about the change.
    localStorage.setItem('user', value);
  }

  get localStorageUser() {
    return localStorage.getItem('user');
  }

  SendVerificationMail() {
    return this.afAuth.currentUser.then(u => u.sendEmailVerification())
      .then(() => {
        //this.router.navigate(['<!-- enter your route name here -->']);
      })
  }

  updateUserEvernote(user){
   return  this.afs.doc(`user/${user.uid}`).update(user);
  }

  updateUser(user){
    user.verifiedProfil = true;
    this.updateGeoUser(user).subscribe(result=>{
      user.geo = result;
      this.afs.doc(`user/${user.uid}`).update(user);
      console.log("Update erfolgreich")
    })

  }
  getUserObj(){

      return JSON.parse(localStorage.getItem('user'));
  }

  updateGeoUser(userObj){
  return   this.globalservice.geocodeAddress(userObj["zip"]+userObj["address2"])
  }


  getUser(){
    let user = JSON.parse(localStorage.getItem('user'));
    if(user) {
      return this.afs.doc(`user/${user.uid}`).valueChanges();
    }

  }

  getUserByUid(uid){
    return this.afs.doc(`user/${uid}`).valueChanges();

  }

  uuidv4() {
    var array = new Uint32Array(10);
    return crypto.getRandomValues(array);
  }

  SignInAccountLinking(email, password, client_id, redirect_uri, state,response_type) {

    let provider = "";

    if(redirect_uri.includes("amazon")){
      provider = "alexa";
    }else{
      provider = "google";
    }
console.log(provider);

    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {

        let temp = this.uuidv4();
        let authcode ="";
        for(let i=0; i<10; i++){
          authcode+= temp[i].toString();
        }
        authcode = authcode.toString();
        this.afs.collection('oauth_'+provider+'_token',ref=>ref.where("refresh_token","==", "431f1597-b762-4c12-8362-ccb0100f1d7e")).valueChanges().subscribe(result=>{
        // ((console.log();
       })


      let temp_uid = result["user"]["uid"];
       console.log(temp_uid);
        this.afs.collection('oauth_'+provider).doc(temp_uid).set({
          code: authcode, uid:temp_uid, created_at: firebase.firestore.FieldValue.serverTimestamp()
        }, {merge:true});
          let uri = redirect_uri+"?state="+state+"&code="+authcode+"&provider="+provider;
         console.log(uri);
            //alert(uri);
        window.location.href = uri;



      }).catch((error) => {
        window.alert(error.message)
      })

  }

  SetNotificationKey(key){

    let notify_key = key;
    let userObj = this.getUserObj();


    return this.afs.doc(`user/${userObj.uid}`).set({notify_key},{merge:true});



  }

  // Sign in with email/password
  SignIn(email, password) {
            return this.afAuth.signInWithEmailAndPassword(email, password);
  }


  // Sign up with email/password
  SignUp(form) {

    return this.afAuth.createUserWithEmailAndPassword(form.email,form.password)
      .then((result) => {
        /* Call the SendVerificaitonMail() function when new user sign
        up and returns promise */
this.SendVerificationMail();

      this.SetUserDataSignUp(result.user);

      //  this.SetUserDataSignUp(result.user,form);

        alert("Registrierung erfolgreich");
     this.afAuth.signInWithEmailAndPassword(form.email, form.password).then((result)=>{

       this.router.navigate(['login']).then(() => {
       });

     });


//        this.router.navigate(['communityOverview']).then(() => {


  //      });


      }).catch((error) => {
        window.alert(error.message)
      })
  }


  acceptGDPR(uid){
      this.afs.doc(`user/${uid}`).set({gdpr:1},{merge:true});
  }

  udpateUser(userObj){
    return this.afs.doc(`user/${userObj.uid}`).set({userObj},{merge:true});
  }

  // Send email verfificaiton when new user sign up




  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
      window.alert('Passwort zurückgesetzt. Bitte folgen Sie die  Anweisung in ihrem Postfach');
      this.router.navigate(['landing']);
    }).catch((error) => {
      window.alert(error)
    })
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    let user = ""
try {
  user = JSON.parse(localStorage.getItem('user'));
} catch(error){
  console.log(error);
}
    return (user !== null) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  FacebookAuth() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider)
    .then((result) => {
      this.SetUserData(result.user);
       this.ngZone.run(() => {
         setTimeout(val=>{ this.router.navigate(['admin'])



         ;}, 1000)

        })
    }).catch((error) => {
      window.alert(error)
    })
  }

  checkVerify(){


  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */

 SetMailVerification(bool,user){




 }
  SetUserDataMailVeriffied(user,bool) {
    this.afs.collection("user")
      .doc(`/${user.uid}`)
      .update({
        "emailVerified": true
      });
  }

  SetUserData(user,form?) {


    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`user/${user.uid}`);

    this.userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,


    };
    return userRef.set(this.userData, {
      merge: true
    })
  }

  SetUserDataSignUp(user) {


    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`user/${user.uid}`);

    this.userData = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };
    return userRef.set(this.userData, {
      merge: true
    })
  }

  // Sign out
  SignOut() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
      this.localStorageUser = '';
      if(this.router.url.match("accountlinking") == null) {
        this.router.navigate(['landing']).then(result=>{
          window.location.reload();
        })

      }
    })
  }

  SignOutWithoutForward() {
    return this.afAuth.signOut().then(() => {
      localStorage.removeItem('user');
    })
  }

}
