import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../services/user.service';
import {AuthService} from '../services/auth.service';
import {CommunityService} from '../services/community.service';
import {EvernoteService} from '../services/evernote.service';

@Component({
  selector: 'app-evernote',
  templateUrl: './evernote.component.html',
  styleUrls: ['./evernote.component.css']
})
export class EvernoteComponent implements OnInit {
  token: string;
  evernote: string;
  evernote_connection: boolean;
  userObj: any;

  constructor(private evernote_service: EvernoteService, private com: CommunityService, private route: ActivatedRoute, private us: UserService, private auth: AuthService) {
    this.auth.getUser().subscribe(data => {
      if (data != undefined) {
        this.userObj = data;
        console.log(this.userObj['uid']);

        this.route.queryParams.subscribe(params => {
          console.log(params);
          this.evernote = params['evernote'];
          this.token = params['token'];
          evernote_service.getAccessToken(this.userObj.evernote_secret, params['oauth_verifier'], params['oauth_token']).subscribe(result => {
            this.userObj.evernote_token = result["oauth_token"];
            this.userObj.evernote_secret = "used";
            this.auth.updateUser(this.userObj);
            this.evernote_connection = true;
          });

        });



      }


    });


  }

  ngOnInit(): void {
  }

}
