import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})

export class ArtikelService {

  constructor(private http: HttpClient) { }

  getArtikel(suchwort, page) {
console.log(suchwort);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/vnd.api+json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Token token=c40e9b60628edbc82bf5e8475e11b257");
    myHeaders.append("Cookie", "_food_repo_session=meB48DvmZC%2FVwXZmkb0Sjv1Cufl6IkzWPKtxF9qDi11GDMjtXnQof4wkHWSX1YKNIUjgJC5L7ZHZ9GiwiRuTpsQJTZqxJ3bJK961htTkCNqn5iL5RzNXlLgnEhXEMzBqxJWZangpGc683qkEG8nRMG6s1p6CHwPckA4oLVDI%2F%2BIOf3GXBxdjWE1y3cArQq8cxiRGdJD%2FaGjEZbfCe9Q2GKXMzJjCWjGPOeeg7NP4IL5TzqEqaHkUHXYVs6M4ysV3FuQIvI6e9JeLuUld1DqJ8b1mTQV0ZmYwBrhBMj76E9MsFRvJ6E3YJFJk1opHoYsOPHyhbfzEsWZQDNpmp5Ofmulg--jqZHLIOoAMtSBcFl--GumjRwC8ddGK%2Bu5QyZLGQg%3D%3D");
let from =0;
    if(page==1){
      from = 0;
    }
    else{
      from = (page-1)*(20);
    }

    var raw = JSON.stringify({
      "_source": {
        "includes": [
          "name_translations",
          "images"
        ]
      },
      "size": 20,
      "from": from,
      "query": {
        "bool": {
          "must": [
            {"match": {"name_translations.de.raw": suchwort}}
          ]
        },

      }

    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,

    };

   return fetch('https://www.foodrepo.org/api/v3/products/_search', requestOptions)



}

  getEanArtikel(ean) {
    const localUrl = 'https://world.openfoodfacts.org/api/v0/product/'+ean+'.json';
    //7const localUrl = 'https://world.openfoodfacts.org/api/v0/product/7622300315733.json';
    return this.http.get(localUrl);
  }




}
