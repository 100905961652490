  <div class="container text-center">
    <!-- Masthead Avatar Image--><img class="logo masthead-avatar mb-0" src="assets/img/logomithintergrund.png" alt="" /><!-- Masthead Heading-->
    <h1 class="masthead-heading text-uppercase mb-0 font-weight-bold">Besorg's Elfriede</h1>
    <!-- Icon Divider-->
    <div class="divider-custom divider-light">
      <div class="divider-custom-line"></div>
    </div>
    <!-- Masthead Subheading-->
    <h4 class="masthead-heading font-weight-bold text-uppercase mb-0">Du - die Community - und  Elfriede. </h4>
    <p class="masthead-subheading font-weight-bold mb-0 pt-2 ">Zusammen seid ihr nicht unterzukriegen und stellt die Versorgung in jeder Lage sicher.</p>
  </div>
      <div class="container pb-4">
        <div class="row pt-4 pb-4"  >
              <div class="col-md-3 pb-2 offset-md-3 text-center">
              <a class="btn  btn-outline" href="/register">Jetzt Registrieren!</a>
              </div>
              <div class="col-md-3 text-center pb-3">
                <a class="btn  btn-outline"  [routerLink]="['/faq']" >Mehr Informationen</a>
              </div>
          </div>
        </div>



<div class="services-container section-container pt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12 ">
            <div class="embed-responsive embed-responsive-16by9">
              <video  class="masthead-avatar" src="assets/video/teaser.mp4" allowfullscreen controls></video>
            </div>

         </div>
      </div>
  </div>
</div>

<!-- About us -->
