import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {
  MapsAPILoader
} from '@agm/core';
import {concatMap, map, switchMap} from 'rxjs/operators';
import {combineLatest, Observable, of} from 'rxjs';
import {GlobalService} from '../services/global.service';
import {AuthService} from '../services/auth.service';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {EinkaufszettelService} from '../services/einkaufszettel.service';
import {AngularFireStorage} from '@angular/fire/storage';
import {EvernoteService} from '../services/evernote.service';
import {ActivatedRoute} from '@angular/router';


@Component({
  selector: 'app-einkaufszettel-global',
  templateUrl: './einkaufszettel-global.component.html',
  styleUrls: ['./einkaufszettel-global.component.css']
})





export class EinkaufszettelGlobalComponent implements OnInit, AfterViewInit {
  @ViewChild("einkaufszettel_push") divView: ElementRef;
  constructor(private route: ActivatedRoute, private evernote: EvernoteService, private afStore: AngularFireStorage, private einkaufszettelservice: EinkaufszettelService, private changeDetector: ChangeDetectorRef, private modalService: NgbModal, private ngZone: NgZone, private auth: AuthService, private global: GlobalService, private mapsApiLoader: MapsAPILoader) {





    this.auth.getUser().pipe(
      switchMap(val => {
        this.userData = val;
        this.auth.updateGeoUser(this.userData);
        const userData = val;
        this.setCurrentLocation();
        return combineLatest([this.global.getEinkaufszettelGlobal()]).pipe(map(combinedData => ({
          userData,
          combinedData
        })));
      }),
      switchMap(result => {

        this.einkaufszettel_places = [];
        this.einkaufszettel_users = [];
        this.einkaufszettel = [];
        this.einkaufszettel = result.combinedData[0];

        if (!this.einkaufszettel.length) {
          this.ready = true;
        }


        const observables_user = [];
        const thisreference = this;
        this.einkaufszettel_andere = [];
        this.einkaufszettel.forEach(function(value) {
          if (value.zielperson == 'andere') {
            thisreference.einkaufszettel_andere.push(value);
            observables_user.push(of('andere')) ;
          } else {
            observables_user.push(auth.getUserByUid(value.userId));
          }
        });
        return combineLatest(observables_user).pipe(map(results => ({results})));
      }),

      switchMap(result => {

        const thisreference = this;
        this.einkaufszettel_users = result.results;
        let i3 = 0;







        let i2 = 0;
        this.einkaufszettel_users.forEach(value => {
          if (value == 'andere') {
            value = {};
            value.geo = thisreference.einkaufszettel_andere[i3].geo;
            i3++;
          }

          thisreference.einkaufszettel_places[i2] = value.geo;
          i2++;
        });

        let i = 0;
        this.einkaufszettel_places.forEach(function(value) {
          thisreference.einkaufszettel[i].lat = value.lat;
          thisreference.einkaufszettel[i].lng = value.lng;
          thisreference.einkaufszettel[i].distance = thisreference.calculateDistance(value.lat, value.lng);
          i++;
        });
        const observables_bilder = [];
        this.einkaufszettel.forEach(value => {
  if (value.typ == 'analog') {
    const ref = this.afStore.ref(`${value.pfad}`);
    observables_bilder.push(ref.getDownloadURL());
  }

        });

        if (observables_bilder.length == 0) {
          const temp: any = result;
          const observable = of('nothing');
          return observable;

        }
        return combineLatest(observables_bilder).pipe(map(results => ({results})));
      })
    ).subscribe(result => {
      const temp: any = result;

      let i = 0;
      let i2 = 0;

      this.einkaufszettel.forEach(value => {
        if (value.typ == 'analog') {
         value.image_url = temp.results[i2];

         i2++;
        }
        i++;

      });

      this.punkte_buendeln();
      this.ready = true;



    });


  }


uniquezettel: any;
  private geoCoder: any;
showzettelid: string;
  closeResult: string;
  states = {};
  einkaufszettel_modal: any = [];
latitude = 0;
ready = false;
  userObj: any;
  longitude = 0;
  address: any;
  mapsLoaded = false;
  einkaufszettel: any;
  einkaufszettel_users: any;
  einkaufszettel_places: any;
  userData: any;
  zoom: number;
  radius = 5;
  einkaufszettel_users_modal: any = [];
  einkaufszettel_same_places: any = [];
  einkaufszettel_same_users: any = [];
  einkaufszettel_same: any = [];
  einkaufszettel_same_places_ids: any = [];
  einkaufszettel_andere: any = [];
  cluster_styles: any = [
    {
      textSize: 14,
      textColor: '#000000',
      url: 'assets/img/m1.png',
     height: 57,
      width: 57,
      anchor: [50, 0]
    }
  ];
  @ViewChild('search', {static: false})
  public searchElementRef: ElementRef;
  @ViewChild('einkaufszettel_ansicht', {static: false})
  public einkaufszettelansicht: ElementRef;
  arrayOne(n: number): any[] {
    return Array(n);
  }

  sendToEvernote(art) {
    console.log(art);
    let notestring = 'Bemerkung: ' + art.bemerkung + '<br></br>' + 'Zieldatum: ' + art.zieldatum + '<br></br>';
    art.artikelListe.forEach(element => {
      notestring += '<en-todo/>' + element.menge + ' ' + element.einheit + ' ' + element.name + '<br></br>';
    });
    console.log(notestring);
    this.evernote.createNode(art.name, this.userData.evernote_token, notestring).subscribe(event2 => {
     alert('Notize erfolgreich exportiert');
     console.log('event2', event2);
    });
  }


punkte_buendeln() {
this.einkaufszettel_same_places = [];
const thisreference = this;


this.einkaufszettel.forEach(value => {

  /* 1. Mehrfach vorkommende Koordinaten identifizieren */
let times = 0;
const found =  thisreference.einkaufszettel.find(function(element) {
if (element.lat == value.lat && element.lng == value.lng) {
  times++;
}
  });

value.times = times;

if (times > 1) {
    value.often = true;
    this.einkaufszettel_same_places.push(value);
  } else {
    value.often = false;
  }
});


  /* 2. Mehrfache vorkommende Orte in separates Array auslagern (this.einkaufszettel_same_places) */
const temparray = [];
this.einkaufszettel_same_places.forEach(function(item, index, object) {
    let temp: any;
    temp = item;
    temp.ids = [];
    const stellen = [];
    let index_stellen = 0;
    thisreference.einkaufszettel_same_places.forEach(value => {

      if (value.lat == item.lat && value.lng == item.lng) {
        stellen.push(index_stellen);
      }
      index_stellen++;


    });
    for (let i = 0; i < stellen.length; i++) {

      temp.ids.push(thisreference.einkaufszettel_same_places[stellen[i]].uid);

    }
    const tempids = [];
    for (let i = 0; i < temparray.length; i++) {
      for (let i2 = 0; i2 < temparray[i].ids.length; i2++) {

       tempids.push(temparray[i].ids[i2]);
      }
    }

    let double = false;
    for (let i = 0; i < tempids.length; i++) {

      if (temp.uid == tempids[i]) {
        double = true;
      }

    }

    if (!double) {
      temparray.push(temp);
    }
 });

this.einkaufszettel_same_places = temparray;




/*

  var a = this.einkaufszettel_same_places.reduce((accumulator, current) => {
    if (checkIfAlreadyExist(current)) {
      return accumulator;
    } else {
      return [...accumulator, current];
    }

    function checkIfAlreadyExist(currentVal) {
      return accumulator.some((item) => {
        if(item.lat === currentVal.lat && item.lng === currentVal.lng) {
           item.id.push(currentVal.id[0]);
          return (item.lat === currentVal.lat &&
            item.lng === currentVal.lng);
        }
      });
    }
  }, []);

  this.einkaufszettel_same_places = a;
console.log(this.einkaufszettel);

*/


}



  private setCurrentLocation() {
    if (this.latitude == 0) {
       this.latitude = this.userData.geo.lat;
       this.longitude = this.userData.geo.lng;
    }
    const thisreference = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {

        thisreference.latitude = position.coords.latitude;
        thisreference.longitude = position.coords.longitude;
      }, function() {
      });
    } else {
      // Browser doesn't support Geolocation

    }



    }

  setlatlng(lat, lngt) {
    this.latitude = lat;
    this.longitude = lngt;
    this.updateDistances();
  }

  updateDistances() {

    let i = 0;
    const thisreference = this;
    this.einkaufszettel_places.forEach(function(value) {

      thisreference.einkaufszettel[i].distance = thisreference.calculateDistance(value.lat, value.lng);
      i++;
    });

  }


  getAddress(latitude, longitude) {

    this.geoCoder.geocode({location: {lat: latitude, lng: longitude}}, (results, status) => {

      if (status === 'OK') {

        if (results[0]) {

          this.zoom = 12;

          this.address = results[0].formatted_address;

        } else {

          window.alert('No results found');

        }

      } else {

        window.alert('Geocoder failed due to: ' + status);

      }


    });

  }

  takeEinkauf(artid, einkaufszettelmodal) {

    this.einkaufszettelservice.takeEinkauf(artid, this.userData.uid);
    this.open(einkaufszettelmodal);


  }

  whatsappexport(artid) {
  }

  evernoteexport(artid) {
    this.evernote.createNode('testi', this.userData.evernote_token, 'etzaber');


  }



 calculateDistance(lat, lng) {
    const point1 = new google.maps.LatLng(this.latitude, this.longitude);
    const point2 = new google.maps.LatLng(lat, lng);
    const distance = google.maps.geometry.spherical.computeDistanceBetween(point1, point2);
    return distance;
  }





  ngAfterViewInit(){
    this.showzettelid = "";
    const first_param = this.route.snapshot.queryParamMap.get('id');
console.log("firstparam", first_param);
    if(first_param.length>0){
      this.showzettelid = first_param;
      this.open(this.divView);

    }
  }
  ngOnInit() {
    // load Places Autocomplete
    this.mapsApiLoader.load().then(() => {

      this.geoCoder = new google.maps.Geocoder;

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          let i = 0;
          const thisreference = this;
          this.einkaufszettel_places.forEach(function(value) {
            thisreference.einkaufszettel[i].lat = value.lat;
            thisreference.einkaufszettel[i].lng = value.lng;
            thisreference.einkaufszettel[i].distance = thisreference.calculateDistance(value.lat, value.lng);
            i++;
          });
          this.zoom = 12;
        });
      });
    });

  }

  openEinkaufszettel(index) {
    this.einkaufszettel_modal = [];
    this.einkaufszettel_modal.push(this.einkaufszettel[index]);
    this.einkaufszettel_users_modal.push(this.einkaufszettel_users[index]);
  }
openEinkaufszettelSamePlaces(index) {

    this.einkaufszettel_modal = [];
    this.einkaufszettel_users_modal = [];
    for (let i = 0; i < this.einkaufszettel_same_places[index].ids.length; i++) {
      const found = this.einkaufszettel.find(element => element.uid == this.einkaufszettel_same_places[index].ids[i]);
      this.einkaufszettel_modal.push(found);
      const found2 = this.einkaufszettel_users.find(element => element.uid == found.userId);
      this.einkaufszettel_users_modal.push(found2);

    }

}
  open(content) {


    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',   windowClass: 'app-modal-window'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  changeRadius(value) {
    this.radius = value;

  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  rerendering() {

  }

  create_geodata_touser() {

    this.global.createGeosforAllUser();


  }

  ngAfterContentInit(): void {
    this.mapsApiLoader.load().then(() => {

    });
  }


}
