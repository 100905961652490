<div class="container pt-5 justify">
       <div class="col-md-12 pb-5" style="">
         <h4 class="masthead-heading text-uppercase mb-0 text-center">Jetzt registrieren</h4>
       </div>

         <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm)" class="needs-validation " novalidate>

<div class="row justify-content-center">
  <div class="col-md-6">
    <div class="formGroup">
      <button style="" class="btn btn-primary btn-lg btn-block" type="button"  (click)="user.GoogleAuth()">
        <i class="fab fa-google fa-2x pr-2"></i>
         Login mit Google
      </button>
    </div>
  </div>


</div>

           <div class="row pt-4 pb-4 justify-content-center">
             <div class="col-md-6 text-center h5">
               oder
             </div>
           </div>
           <div class="row justify-content-center">
               <div class="col-md-6">
                 <label for="email">Email</label>
               </div>

           </div>
           <div class="row justify-content-center">
             <div class="col-md-6">
                 <input [(ngModel)]="model.email"  type="email" class="form-control" name="email" placeholder="du@email.com" required
                  #email="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && email.invalid }">
             </div>

           </div>

                 <div class="invalid-feedback">
                   Bitte einen gütlige Email angeben
                 </div>


          <div class="row justify-content-center pb-3">
               <div class="col-md-6">
                 <label for="username">Passwort</label>
                 <div class="input-group">
                   <input [(ngModel)]="model.password"  type="password" class="form-control" name="password" placeholder="****" required
                   #password="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && password.invalid }">
                   <div class="invalid-feedback" style="width: 100%;">
                     Bitte gültiges Passwort eingeben
                   </div>
                 </div>
               </div>
           </div>

           <div class="row justify-content-center">
                <div class="col-md-6 text-left">
                     <div class="custom-control custom-checkbox">

                       <label for="datenschutz">  <input [(ngModel)]="model.data1" type="checkbox" class="form-check-input" name="data1" required
                                                         id="datenschutz"         #data1="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && data1.invalid }">Akzeptiere die <a href="/datenschutz"><u>Datenschutzbedingungen</u></a>



                       </label>
                       <div class="invalid-feedback">
                         Bitte Datenschutz akzeptieren
                       </div>
                     </div>
                     <div class="custom-control custom-checkbox">

                       <label for="agb"><input [(ngModel)]="model.data2" type="checkbox"  name="data2" required
                                               id="agb"               #data2="ngModel" class="form-check-input" [ngClass]="{ 'is-invalid': userForm.submitted && data2.invalid }">Akzeptiere die <a href="./assets/AGB_Elfriede.pdf"><u>AGB's</u></a></label>
                           <div class="invalid-feedback">
                             Bitte AGB's akzeptieren
                           </div>
                     </div>
               </div>
             </div>
           <div class="pb-5 row justify-content-center">
              <div class="col-md-6 pb-5">
                <button class="btn btn-primary btn-lg btn-block " type="submit">Jetzt helfen</button>
              </div>

           </div>

        <!-- Continue with Google -->

