<div class="container"></div>
<div *ngIf="ready==0" class="container">

  <div class="d-flex p-2  justify-content-center"><img class="spinner" src="https://besorgselfriede.org/assets/img/logomithintergrund.png" /></div>
  <div class="d-flex p-2  justify-content-center">
    <img class="img-responsive" style=" max-width: 5%; max-height:5%;
  width:100%; height:100%" src="assets/img/ci_loader.gif"/>
  </div>
</div>
<div *ngIf="ready==1" class="container justify-content-center">
<td class="container-fluid container pb-5 justify-content-center">
  <div class="row text-center container-fluid">
    <div class="col ">
      <h4 class=" masthead-heading text-uppercase mb-0 text-center pt-4"> Was ist los in meiner Community? ({{communityObj?.titel}})</h4>

    </div>
  </div>
  <div *ngIf="userObj.gdpr ==0" class="row justify-content-center" style="border:1px solid;margin-top:10px;">
    <div class="col pt-3 masthead-heading ">
      <h5>Datenschutz Hinweis:</h5>
    </div>
    <div class="col-12  font-weight-bolder text-left">
      Du hast unseren Datenschutzbestimmungen noch nicht zugestimmmt. Das ist nicht nur für uns wichtig sondern sorgt auch dafür, dass dich
      alle Communitymitglieder beliefern können, wenn du einen Einkaufszettel aufgibst.
      Gib uns dein "OK" und stimme den Datenschutzbestimmungen zu:
      <span style="margin-left:10px;cursor:pointer;" (click)="acceptGDPR(userObj.uid)" >
        <i class="fa fa-check" aria-hidden="true"></i> Akzeptieren
      </span>
    </div>
  </div>
  <div class="row justify-content-center container-fluid">


    <div class="col pt-3 masthead-heading  ">
      <h5>Community-User:</h5>
    </div>

  </div>
  <div *ngIf="mobile==false" class="table-responsive">
    <table class="table-borderless table text-white">
      <thead class="font-weight-bolder">
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Rolle</th>
        <th scope="col">Straße</th>
        <th scope="col">PLZ</th>
        <th scope="col">Action</th>
      </tr>
      </thead>
      <tbody>

      <tr *ngFor="let user of users" >

        <td >{{user.userObj.firstname}} {{user.userObj.lastname}}</td>

        <td>
          <div class="" *ngIf="user.role == 2"><a triggers="click mouseenter hover" [autoClose]="true" placement="bottom" ngbTooltip="Teamkapitän"><i class="fas fa-user-shield"></i></a> </div>
          <div class="" *ngIf="user.role == 1"><a triggers="click mouseenter hover" [autoClose]="true" placement="bottom" ngbTooltip="Community-Mitglied"><i class="fas fa-street-view"></i></a> </div>
        </td>
        <td>
          <div *ngIf="user.userObj.gdpr == 1">  {{user.userObj.address1}}   </div>
          <div *ngIf="user.userObj.gdpr == 0">    *** </div>
        </td>
        <td>
          <div *ngIf="user.userObj.gdpr == 1">  {{user.userObj.zip}} </div>
          <div *ngIf="user.userObj.gdpr == 0">    *** </div>
        </td>
        <td  *ngIf="userObj.communityRole == 2">
          <a triggers="click mouseenter hover" [autoClose]="true" placement="bottom"
             ngbTooltip="Du kannst User aus deiner Community löschen, klicke einfach hier">   <span (click)="removeUser(user.uid)" >
                  <i class="fa fa-trash"></i>
              </span></a>
          <a triggers="click mouseenter hover" [autoClose]="true" placement="bottom"
             ngbTooltip="Jeder, der deine Community betreten will muss von dir bestätigt werden. Klicke einfach um die Person zu bestätigen"><span *ngIf="user.activated == 0"(click)="activateUser(user.userObj.uid)" style="margin-left:10px;" >
                  <i class="fa fa-user-plus"></i>
                </span></a>
          <a triggers="click mouseenter hover" [autoClose]="true" placement="bottom"
             ngbTooltip="Du kannst andere Community-Mitglieder ebenfalls zum Team-Kapitän ernennen"><span *ngIf="user.role == 1"(click)="make_captain(user.userObj.uid)" style="margin-left:10px;" >
                 <i class="fas fa-flag"></i>
                </span></a>
          <a triggers="click mouseenter hover" [autoClose]="true" placement="bottom"
             ngbTooltip="Falls du diesen Team-Kapitän wieder entlassen möchstest, klicke hier"><span *ngIf="user.role == 2"(click)="make_community(user.userObj.uid)" style="margin-left:10px;" >
              <i class="fas fa-user-slash"></i>
              </span></a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="mobile==true"  class="row pt-3 pl-3 container-fluid">


      <div class="m-3 w-100" style="background-color:#00000026" *ngFor="let user of users" >

        <div class="pt-2 pl-2 row" ><span class="pl-3 pr-2 font-weight-bold">Name: </span> {{user.userObj.firstname}} {{user.userObj.lastname}}</div>

        <div class="pl-2 row"><span class="pl-3 pr-2 font-weight-bold">Rolle: </span>
          <div class="" *ngIf="user.role == 2"><a triggers="click mouseenter hover" [autoClose]="true" placement="bottom" ngbTooltip="Teamkapitän"><i class="fas fa-user-shield"></i></a> </div>
          <div class="" *ngIf="user.role == 1"><a triggers="click mouseenter hover" [autoClose]="true" placement="bottom" ngbTooltip="Community-Mitglied"><i class="fas fa-street-view"></i></a> </div>
        </div>
        <div class="pl-2 row"><span class="pl-3 pr-2 font-weight-bold">Straße: </span>
          <div class="pl-2" *ngIf="user.userObj.gdpr == 1">  {{user.userObj.address1}}   </div>
          <div  class="pl-2" *ngIf="user.userObj.gdpr == 0">    *** </div>
        </div>
          <div class="pl-2 row"><span class="pl-3 pr-2 font-weight-bold">Ort: </span>
            <div  class="pl-2" *ngIf="user.userObj.gdpr == 1">  {{user.userObj.zip}} </div>
            <div  class="pl-2" *ngIf="user.userObj.gdpr == 0">    *** </div>
            <div  class="pl-2" *ngIf="userObj.communityRole == 2">
            </div>

        </div>
        <div class="pl-2 row justify-content-center">
          <a triggers="click mouseenter hover" [autoClose]="true" placement="bottom"
             ngbTooltip="Du kannst User aus deiner Community löschen, klicke einfach hier">   <span (click)="removeUser(user.uid)" >
                  <i class="fa fa-trash"></i>
              </span></a>
          <a triggers="click mouseenter hover" [autoClose]="true" placement="bottom"
             ngbTooltip="Jeder, der deine Community betreten will muss von dir bestätigt werden. Klicke einfach um die Person zu bestätigen"><span *ngIf="user.activated == 0"(click)="activateUser(user.userObj.uid)" style="margin-left:10px;" >
                  <i class="fa fa-user-plus"></i>
                </span></a>
          <a triggers="click mouseenter hover" [autoClose]="true" placement="bottom"
             ngbTooltip="Du kannst andere Community-Mitglieder ebenfalls zum Team-Kapitän ernennen"><span *ngIf="user.role == 1"(click)="make_captain(user.userObj.uid)" style="margin-left:10px;" >
                 <i class="fas fa-flag"></i>
                </span></a>
          <a triggers="click mouseenter hover" [autoClose]="true" placement="bottom"
             ngbTooltip="Falls du diesen Team-Kapitän wieder entlassen möchstest, klicke hier"><span *ngIf="user.role == 2"(click)="make_community(user.userObj.uid)" style="margin-left:10px;" >
              <i class="fas fa-user-slash"></i>
              </span></a>
        </div>

<div class="row pt-3"></div>
      </div>
  </div>










  <div class="row justify-content-center pt-2">
    <div class="col pt-3 masthead-heading ">
      <h5>Einkaufszettel digital:</h5>
    </div>

  </div>

  <div class="row container-fluid" style="color:black;">
  <div class="col-sm-6 mt-4" *ngFor="let art of einkaufszettelObj; let i = index">
        <div class="card"   >
         <h5 *ngIf="art.zielperson=='mich'" class="card-header">#{{i+1}} {{art.name}} für {{art.userObj.firstname}} {{art.userObj.lastname}}, {{art.userObj.address1}}  {{art.userObj.zip}} , {{art.userObj.phone}}  (Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})<span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span>    <span (click)="takeEinkauf(art.uid)"><i (click)="takeEinkauf(art.uid)" style="margin-left:50px;" class="fas fa-clipboard-check"></i></span><span (click)="evernoteexport(art.uid)" *ngIf="userObj.evernote_token"><i  style="margin-left:10px; color:green"class="fab fa-evernote"></i></span><span  (click)="whatsappexport(art.uid)"><i style="margin-left:10px; color:green"class="fab fa-whatsapp"></i></span></h5>
          <h5 *ngIf="art.zielperson=='andere'" class="card-header">#{{i+1}} {{art.name}} für {{art.zielperson_name}} , {{art.zielperson_strasse}} , {{art.zielperson_ort}} , {{art.zielperson_telefon}}  (Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}}) <span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span>     <span (click)="takeEinkauf(art.uid)"><i (click)="takeEinkauf(art.uid)" style="margin-left:50px;" class="fas fa-clipboard-check"></i></span><span (click)="evernoteexport(art.uid)"*ngIf="userObj.evernote_token"><i  style="margin-left:10px; color:green" class="fab fa-evernote"></i></span><span  (click)="whatsappexport(art.uid)"><i style="margin-left:10px; color:green"class="fab fa-whatsapp"></i></span></h5>
             <ul class="list-group list-group-flush">
                <li style="display: inline" class="list-group-item" *ngFor="let art2 of art.artikelListe;let a = index" >
                  {{a+1}}. {{art2.name}} ({{art2.brand}}) <span *ngIf="art2.menge">(Menge: {{art2.menge}} {{art2.einheit}}) </span> <span *ngIf="art2.bemerkung">(Bemerkung: {{art2.bemerkung}}) </span>
                  <div class="col-sm">
                    <img style="width:30px" src={{art2?.image_url}} >
                  </div>
                 </li>
             </ul>
        </div>
    </div>
  </div>
  <div class="row justify-content-center pt-2">
    <div class="col pt-3 masthead-heading ">
      <h5>Einkaufszettel analog:</h5>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-6 text-left" *ngFor="let bild of bilderList; let i=index" >

          <div *ngIf ="analogListStatus[i]== 1 || analogListStatus[i]== undefined ">
          <span (click)="takeEinkaufAnalog(analogList[i],analogList[i])"><i (click)="takeEinkaufAnalog(analogList[i],analogList[i])" style="margin-left:50px;" class="fas fa-clipboard-check"></i> Erstellt für: {{usersanalog[i].firstname}} {{usersanalog[i].lastname}}</span>
          <img class="img-fluid" src="{{bild}}" >
          </div>

        </div>
      </div>
    </div>


  </div>
</div>
