import { Component, OnInit } from '@angular/core';
import {environment} from '../../environments/environment';
import {Community} from '../shared/services/community';
import {Artikel} from '../shared/services/artikel';
import {Location} from '@angular/common';
import { AuthService } from '../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunityService} from '../services/community.service';
import {User} from '../shared/services/user';
import {Einkaufszettel} from '../shared/services/einkaufszettel';
import pdfMake from 'pdfmake/build/pdfmake';
import { Plugins } from '@capacitor/core';
const { Browser } = Plugins
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
@Component({
  selector: 'app-print-einkaufszettel',
  templateUrl: './print-einkaufszettel.component.html',
  styleUrls: ['./print-einkaufszettel.component.css']
})
export class PrintEinkaufszettelComponent implements OnInit {
  userObj: User;
  adminUser: User;
  userVerified: boolean = false;
  firstName: string;
  userId: string;
  communityObj: Community;
  artikelObj: Artikel;
  einkaufszettelObj: Einkaufszettel;
  artikelListe: Artikel[];
  uid: string;
  users: unknown[];
  firstnames: string[];
  lastnames: string[];
  userObserver;
  community_name: string;
  useExistingCss: boolean;
  styleName:string;
  ready:any;
  constructor(private com: CommunityService, private location: Location,  private auth: AuthService, private router: Router, private route: ActivatedRoute) {






    this.auth.getUser().subscribe(data => {
        if (data != undefined) {
          this.userObj = data;

          this.ready =1;

        }
      }
    );



  }

  ngOnInit(): void {


    const observable1 =  this.auth.getUser();

    observable1.subscribe(data => {
        if (data != undefined) {
          this.userObj = data;
         }
      }
    )





  }





  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }



 async openlink(link){

    await Browser.open({ url: link });




  }




  async showPdf() {
    this.ready =0;
    console.log("showpdf");
     pdfMake.vfs = pdfFonts.pdfMake.vfs;
    let docDefinition = {
      pageSize: 'LETTER',
      background: [
        {

          image: await this.getBase64ImageFromURL(
            "assets/img/EINKAUFSLISTE.png"

          ),
          width:550,
          style: 'header'


        }
      ],
      content: [

        {
       //   text: 'Ihr Ansprechpartner:\n'+this.userObj.firstname +' '+ this.userObj.lastname+'\n '+this.userObj.address1+'\n '+this.userObj.zip+' '+this.userObj.address2+'\n'+'Telefonnummer: '+this.userObj.phone+'\n'+'E-Mail: '+this.userObj.email+'\n',
         // absolutePosition: {x: 230, y: 645}
        }


      ],
      styles: {
        header: {
          fontSize: 30,
          bold: true,
          alignment: 'center'
        }
      }
    };

    pdfMake.createPdf(docDefinition).showPdf();
    this.ready =1;
    //this.location.back();
  }

}
