<div class="container">
  <div class="col-md-12  text-center" style="margin-top:20px;" *ngIf="emailVerifiedNote">
      <h2 class="pt-5"><strong>Email Verifizierung</strong></h2>
          <div class="cnt-block equal-hight pt-4  m-0 text-center" *ngIf="!emailSent" >
            Bitte bestätige deine Mail-Adresse, dann können wir auch gleich starten. Wir haben dir an deine angegeben Email-Adresse einen
            Link geschickt, denn du einfach nur bestätigen musst.<br><br>
          <div class="text-center">
            <a class="btn text-center" #link1  (click)="pageReload()" >  Email Verifizierung  prüfen</a>
          </div>
          <br><br>


          </div>
          <div class="cnt-block equal-hight  m-0 text-center" *ngIf="emailSent" >
            Wir haben dir erneut eine Email geschickt.
            Nachdem du den Link geklickt hast, geht's hier weiter.
            <div class="text-center">
              <a class="btn text-center" (click)="pageReload()">  Email Verifizierung  prüfen</a>
            </div>
          </div>

          Sollte die Email nicht angekommen sein, kannst du dir diese gern nochmal schicken lassen: <br>
           <p style="cursor:pointer; color: #4fea4f; text-decoration: underline" #link2 (click)="sendVerificationMail()"> E-Mail erneut senden</p><br>
           Bitte prüfe auch deinen SPAM Ordner.



  </div>
</div>


<div class="container">
  <div class="col-md-6 offset-md-3 text-center" style="margin-top:5px"  *ngIf="!auth.isLoggedIn && !emailVerifiedNote ">
      <div class="card-body">
           <h1>Login</h1>
            <form>
              <div class="input-group form-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-user"></i></span>
                </div>
                <input type="text" [(ngModel)]="username" [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Benutzer">

              </div>
              <div class="input-group form-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="fas fa-key"></i></span>
                </div>
                <input type="password"  [(ngModel)]="password"  [ngModelOptions]="{standalone: true}" class="form-control" placeholder="Passwort">
              </div>
              <div class="form-group">
                <button  value="Login" (click)="login()" class="btn btn-primary btn-lg btn-block">Login</button>
              </div>
            </form>
            <div class="formGroup">
              <button type="button" class="btn btn-primary btn-lg btn-block" style="vertical-align: middle;" (click)="auth.GoogleAuth()" >
                <i class="fab fa-google-plus-g fa-1x"></i>  Login mit Google
              </button>
            </div>
      </div>
  </div>
  <div class="col-md-6 offset-md-3 text-center" style="margin-top:5px"  *ngIf="!auth.isLoggedIn">
          <div class="d-flex justify-content-center links">
            Noch kein Helfer? <a href="/register">Jetzt registrieren</a>
          </div>
          <div class="d-flex justify-content-center">
            <a href="forgotPassword">Passwort vergessen?</a>
          </div>
    </div>
</div>

<div *ngIf="ready==1">

  <div class="container"  *ngIf="!userVerified">
         <div class="col-md-12 order-md-1 pb-4" style="margin-top:5px">
           <h4 class="mb-3 text-center">Was wir unbedingt noch von dir wissen müssen</h4>
              <div class="col-md-9" style="background-color:#A4DD5F;width:100%;text-align:right;">
                80% geschafft
              </div>
           <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm)" style="margin-top:10px;" class="needs-validation mb-4" novalidate>
             <div class="row">
               <div class="col-md-6 mb-3">
                 <label for="firstName1">Vorname</label>
                 <input type="text" [(ngModel)]="userObj.firstname"   class="form-control" name="firstname" placeholder="Vorname" required
                        #firstname="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && firstname.invalid }">
                 <div class="invalid-feedback">
                   Bitte gültigen Vornamen angeben.
                 </div>


               </div>
               <div class="col-md-6 mb-3">
                 <label for="lastName">Nachname</label>
                 <input type="text" [(ngModel)]="userObj.lastname" class="form-control" name="lastName" placeholder="Nachname" value="" required
                 #lastName="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && lastName.invalid }">
                 <div class="invalid-feedback">
                   Bitte gültigen Nachnamen angeben.
                 </div>
               </div>
             </div>


             <div class="mb-3">
               <label for="address">Strasse & Hausnummer</label>
               <input type="text" [(ngModel)]="userObj.address1"   class="form-control" name="address1" placeholder="Banhofstrasse 24" required
               #address1="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && address1.invalid }">
               <div class="invalid-feedback">
                 Bitte eine gültige Adresse
               </div>
             </div>

             <div class="mb-3">
               <label for="address2">Ort <span class="text-muted"></span></label>
               <input type="text" [(ngModel)]="userObj.address2"   class="form-control" name="address2" placeholder="Ort" required
                      #address2="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && address2.invalid }">
               <div class="invalid-feedback">
                 Bitte gültigen Ort angeben
               </div>
             </div>
             <div class="mb-3 ">
               <label for="zip">Postleitzahl</label>
               <input type="text" [(ngModel)]="userObj.zip"  class="form-control" name="zip" placeholder="PLZ" required
               #zip="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && zip.invalid }">
               <div class="invalid-feedback">
                 Bitte gültige Postleitzahl angeben
               </div>
             </div>
             <div class="mb-3 ">
               <label for="phone">Telefonnummer</label>
               <input type="text" [(ngModel)]="userObj.phone"  class="form-control" name="phone" placeholder="Festnetz/Handynummer" required
                      #phone="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && phone.invalid }">
               <div class="invalid-feedback">
                 Bitte gültige Telefonnummer angeben
                </div>
             </div>

             <hr class="mb-4">
             <button class="btn btn-primary btn-lg btn-block" type="submit">Profil vervollständigen und ab zur guten Tat</button>
           </form>
         </div>
  </div>
</div>
