import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

import { Route, ActivatedRoute, ParamMap } from '@angular/router';
@Component({
  selector: 'app-account-linking',
  templateUrl: './account-linking.component.html',
  styleUrls: ['./account-linking.component.css']
})
export class AccountLinkingComponent implements OnInit {

  client_id: any;
  response_type: any;
  state: any;
  redirect_uri:any;
  artikelliste : any[];
  username: string;
  password: string;

  constructor(public auth:AuthService, private activatedRoute: ActivatedRoute) {

this.auth.SignOut();

  }

  login(){
    if(this.username && this.password){
      this.auth.SignInAccountLinking(this.username,this.password, this.client_id, this.redirect_uri, this.state, this.response_type);

    }


  }

  ngOnInit(): void {

    this.activatedRoute.queryParams.subscribe(params => {
      this.client_id = params['client_id'];
      this.state = params['state'];
      this.redirect_uri = params['redirect_uri'];
     this.response_type = params['response_type'];
    });



  }

}

