import { Component, OnInit } from '@angular/core';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CommunityService } from '../services/community.service';
import { UserService } from '../services/user.service';
import { AuthService } from '../services/auth.service';

import { User } from "../shared/services/user";
import { Community } from "../shared/services/community";

import {map, switchMap} from 'rxjs/operators';
import {combineLatest, forkJoin, Observable} from 'rxjs';


@Component({
  selector: 'app-admindashboard',
  templateUrl: './admindashboard.component.html',
  styleUrls: ['./admindashboard.component.css']
})
export class AdmindashboardComponent implements OnInit {
  faPlusCircle = faPlusCircle;
  uid: string;
  userObj:any;
  communityRole: number = 1;
  community:Community;
  userData:number;
  ready: number = 0;
  private userVerified: boolean;
  private mailVerified: boolean;
  constructor(private route: ActivatedRoute, private router: Router,  private userService:UserService, private auth:AuthService, private com: CommunityService) {

    this.ready = 0;
        // Datenabruf fertig
      this.ready = 1;
      this.userObj =  this.auth.getUser();
      this.uid = this.userObj["uid"];







  }

  ngOnInit(): void {



  }

  exitCommunity(){
    if(confirm("Achtung: Sie verlassen damit die komplette Commmunity. Sind sie sich sicher?")){
    this.userService.exitCommunity(this.auth.getUserObj().uid,this.uid)
    }
  }



}
