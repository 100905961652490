import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { Community } from "../shared/services/community";

import { User } from "../shared/services/user";
import { Einkaufszettel } from "../shared/services/einkaufszettel";
import { Artikel } from "../shared/services/artikel";

import { CommunityService } from '../services/community.service';
import { AuthService } from '../services/auth.service';
import { ArtikelService } from '../services/artikel.service';
import { UserService } from '../services/user.service';
import {animate, keyframes, state, style, transition, trigger} from '@angular/animations';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {variable} from '@angular/compiler/src/output/output_ast';
import Quagga from 'quagga';
export class AppModule { }

import { PhotoService } from '../services/photo.service';
import {GlobalService} from '../services/global.service';



@Component({
  selector: 'app-add-einkaufszettel',
  templateUrl: './add-einkaufszettel.component.html',
  styleUrls: ['./add-einkaufszettel.component.css'],
  animations: [
    trigger('shakeit', [
      state('shakestart', style({
        transform: 'scale(1)',
      })),
      state('shakeend', style({
        transform: 'scale(1)',
      })),
      transition('shakestart => shakeend', animate('1000ms ease-in',     keyframes([
        style({transform: 'translate3d(-1px, 0, 0)', offset: 0.1}),
        style({transform: 'translate3d(2px, 0, 0)', offset: 0.2}),
        style({transform: 'translate3d(-4px, 0, 0)', offset: 0.3}),
        style({transform: 'translate3d(4px, 0, 0)', offset: 0.4}),
        style({transform: 'translate3d(-4px, 0, 0)', offset: 0.5}),
        style({transform: 'translate3d(4px, 0, 0)', offset: 0.6}),
        style({transform: 'translate3d(-4px, 0, 0)', offset: 0.7}),
        style({transform: 'translate3d(2px, 0, 0)', offset: 0.8}),
        style({transform: 'translate3d(-1px, 0, 0)', offset: 0.9}),
      ]))),
    ])]
})
export class AddEinkaufszettelComponent implements OnInit {
   title = 'appBootstrap';
  currentArtikel: any = [];
  closeResult: string;
  states = {};
  arrayOne(n: number): any[] {
    return Array(n);
  }
eancode: any;
  ean_search:boolean = false;
  loaderAnalog:boolean = false;
  successAnalog:boolean = false;
  prozessAnalog:boolean = false;
  showAnalogForm: boolean = false;
  zielperson:string = "mich";
  zielperson_name:string = "";
  zielperson_ort:string = "";
  zielperson_telefon:string = "";
  zielperson_strasse:string = "";
  artikelList: any[];
  einkaufszettelUid:string;
  badge_display:boolean;
  // tslint:disable-next-line:variable-name
  einkaufszettel_name: string;
  einkaufszettel: Array<Artikel> =[];
  uid:string;
  pages_counter: number;
  user: any;
    artikel: any = [];
    pages: number;
    badge_counter: number;
    zieldatum: any;
    errorMessage: any;
    eanartikel:any;
    ean_detection: boolean=false;
    database_found: boolean;
    res_temp: any;
  constructor(public global: GlobalService,public photoService: PhotoService,private modalService: NgbModal, private artikelservice: ArtikelService, private auth: AuthService, private router: Router,private commService: CommunityService, private route:ActivatedRoute){

    this.getAllArtikel();
    this.states['state1'] = 'shakestart';
    this.states['state2'] = 'shakestart';
    this.pages = 0;
    this.pages_counter =1;
    this.badge_counter =0;
    this.badge_display = false;
    this.auth.getUser().subscribe(val =>{
         if(val){
           console.log(val["uid"]);
           this.user = val;
           this.uid= val["communityId"];
         }
    })
  }

  badge_display_f(bool){
    this.badge_display = bool;
  }
  shakeMe(stateVar: string) {
    this.states[stateVar] = (this.states[stateVar] === 'shakestart' ? 'shakeend' : 'shakestart');
  }

  shakeEnd(stateVar: string, event) {
    this.states[stateVar] = 'shakeend';
  }

  showAnalogProzess(){
    this.prozessAnalog = true;
    this.showAnalogForm = true;
  }

  getEanArticle(ean){

this.artikelservice.getEanArtikel(ean).subscribe(result=>{

console.log(result);
if(result["status"]==0){
this.database_found = false;
  this.ean_search=false;
}
else{
  this.database_found=true;
  this.ean_search=true;
  this.eanartikel = result["product"];
}










});



  }



  getArtikel(suchwort, page) {
    this.artikel = [];
    this.pages_counter = page;
    this.artikelservice.getArtikel(suchwort, page)
      .then(response => response.text())
      .then(result => {

        let data2:any = {};
        data2 = JSON.parse(result);
console.log(data2);
       this.pages =Math.ceil(Number(Number(data2["hits"]["total"]) / Number(20)));
console.log("this pages", this.pages);
        this.artikel = [];
        data2.hits.hits.forEach(value=>{
value= value._source;
          let temp ="";

          value.images.forEach(value2=>{

            if(value2.categories[0]=="Front"){

              temp = value2.medium;
            }

          })
          if(temp==""){
            temp = value.images[0].medium;
          }
          value.image_url = temp;
          value.name = value.name_translations.de;

          if(value.name) {
          value.name = value.name.replace("Coop", "");
            value.name = value.name.replace("coop", "");
            value.name = value.name.replace("COOP", "");
            this.artikel.push(value);
          }


        })

      })
      .catch(error => console.log('error', error));
  /* .subscribe(data => {



   });
*/



  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',   windowClass: 'app-modal-window'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

pagesCounter(){
          this.pages_counter++;

    return this.pages_counter;

}


  pagesCounterback(){
    this.pages_counter--;

    return this.pages_counter;

  }
  getAllArtikel(){
      this.commService.getAllArtikel(this.uid).subscribe(data => {

              this.artikelList = data.map(e => {
                                          return {
                                            id: e.payload.doc.id,
                                            ...e.payload.doc.data() as Artikel} ;
                                          })
      });
    }

  addApiArtikelToEinkaufszettel(val,name,  brand, product_name, image_url, categories, html, bemerkung, menge, einheit){

   const artikel:any = {};
    artikel.id = val;
    if(brand==undefined){
      artikel.brand = "";
    }
    else{
      artikel.brand = brand;
    }
    if(product_name==undefined){
      artikel.brand = "";
    }
    else{
      artikel.name = product_name;
    }
    if(categories==undefined){
      artikel.kategorie = "";
    }
    else{
      artikel.kategorie = categories;
    }

     artikel.image_url = image_url;

    artikel.bemerkung = bemerkung;
    artikel.menge = menge;
    artikel.einheit = einheit;

   // Überprüfen ob element bereits im Einkaufszettel
    let einkaufszettel: any;
   einkaufszettel = this.einkaufszettel;
    const result = einkaufszettel.find(einkaufszettels => einkaufszettels.id === artikel.id);


  if(result == undefined) {


    if (this.einkaufszettelUid) {
      this.einkaufszettel.push(artikel);
      this.einkaufszettel_name = name;

    } else {
      this.einkaufszettelUid = "123";
      this.einkaufszettel_name = name;
      this.einkaufszettel.push(artikel);
    }
    this.badge_counter++;

    html.classList.add('shakeit');
    setTimeout(() => {
      html.classList.remove('shakeit');
    }, 800)
  }
  else {
    alert("Artikel befindet sich bereits auf dem Einkaufszettel");
  }

    this.modalService.dismissAll();

  }

  change(event, bool){
    if(bool) {
      event.target.value = "Artikel hinzufügen";
    }
    else {
      event.target.value = "+";
    }
  }
  changezielperson(event, bool, wert){
if(bool){
  this.zielperson = wert;
}

  }

  changeandere(event, feld, wert){
    if(feld=="name"){
      this.zielperson_name = wert;
    }
    if(feld=="plzort"){
      this.zielperson_ort = wert;
    }
    if(feld=="telefon"){
      this.zielperson_telefon = wert;
    }
    if(feld=="strasse"){
      this.zielperson_strasse = wert;
    }


  }

  deleteArtikel(artikel){
    const index: number = this.einkaufszettel.indexOf(artikel);
    if (index !== -1) {
      this.einkaufszettel.splice(index, 1);
    }
    this.badge_counter--;
    }

  addArtikelEigen(artikelname, zettelname, html, field){
    if(artikelname === ''){
      alert("Bitte Artikelnamen eingeben")
    }
    else {
    const artikel:any = {};
    artikel.name = artikelname;
    artikel.brand = "Eigener Artikel";

    if(this.einkaufszettelUid){
      this.einkaufszettel.push(artikel);
      this.einkaufszettel_name = zettelname;

    }
    else{
      this.einkaufszettelUid = "123";
      this.einkaufszettel_name = name;
      this.einkaufszettel.push(artikel);
    }
    this.badge_counter++;
field.value = "";
    html.classList.add('shakeit');

    setTimeout(() => {
      html.classList.remove('shakeit');
    }, 800)

  }

  }





  addToEinkaufszettel(val,name){
    let artikel = this.artikelList.find( artikel => artikel.uid === val);

    if(this.einkaufszettelUid){
      this.einkaufszettel.push(artikel)
    }else{
      this.einkaufszettelUid = "123";
      this.einkaufszettel_name = name;
      this.einkaufszettel.push(artikel);
    }

  }



  saveEinkaufszettel(bemerkung, zieldatum, name){

    var einkaufszettel = ({
      artikelListe : this.einkaufszettel,
      name: name,
      bemerkung: bemerkung,
      zieldatum: zieldatum,
      cre_date : new Date,
      userId: this.user.uid,
      zielperson: this.zielperson,
      zielperson_name: this.zielperson_name,
      zielperson_ort: this.zielperson_ort,
      zielperson_telefon: this.zielperson_telefon,
      zielperson_strasse: this.zielperson_strasse,
      typ: "digital"
    } as Einkaufszettel);

      if(this.zielperson=="andere"){
      this.global.geocodeAddress(this.zielperson_ort).subscribe(result=>{
        let geo = result;
        var einkaufszettel = ({
          artikelListe : this.einkaufszettel,
          name: name,
          bemerkung: bemerkung,
          zieldatum: zieldatum,
          cre_date : new Date,
          userId: this.user.uid,
          zielperson: this.zielperson,
          zielperson_name: this.zielperson_name,
          zielperson_ort: this.zielperson_ort,
          zielperson_telefon: this.zielperson_telefon,
          zielperson_strasse: this.zielperson_strasse,
          typ: "digital",
          geo: geo
        } as Einkaufszettel);
        console.log("einkaufszettel");
        console.log(einkaufszettel);
        this.commService.saveEinkaufszettel(einkaufszettel,this.uid).then(result=>{
          alert("Einkaufszettel angelegt");
          this.router.navigate(['admin']);
        });


      })
    } else {

        console.log("einkaufszettel");
        console.log(einkaufszettel);
        this.commService.saveEinkaufszettel(einkaufszettel, this.uid).then(result => {
          alert("Einkaufszettel angelegt");
          this.router.navigate(['admin']);
        });
      }

  }

  addPhotoToGallery(bemerkung, zieldatum, name) {


    this.showAnalogForm = false;
     this.photoService.addNewToGallery().then(result=>{

      console.log(result);
        if(result.state =="success"){
          this.loaderAnalog = true;
          if(this.zielperson=="andere"){
            this.global.geocodeAddress(this.zielperson_ort).subscribe(result2=>{
              let geo = result2;
              var einkaufszettel = ({
                artikelListe: null,
                name: name,
                bemerkung: bemerkung,
                zieldatum: zieldatum,
                cre_date: new Date,
                userId: this.user.uid,
                zielperson: this.zielperson,
                zielperson_name: this.zielperson_name,
                zielperson_ort: this.zielperson_ort,
                zielperson_telefon: this.zielperson_telefon,
                zielperson_strasse: this.zielperson_strasse,
                typ: "analog",
                pfad: result.ref.fullPath,
                nameFile: result.ref.name,
                geo: geo
              } as Einkaufszettel);
              console.log("einkaufszettel");
              console.log(einkaufszettel);
              this.commService.saveEinkaufszettel(einkaufszettel,this.uid).then(result=>{
                alert("Einkaufszettel angelegt");
                this.router.navigate(['admin']);
              });


            })
          } else {
            var einkaufszettel = ({
              artikelListe: null,
              name: name,
              bemerkung: bemerkung,
              zieldatum: zieldatum,
              cre_date: new Date,
              userId: this.user.uid,
              zielperson: this.zielperson,
              zielperson_name: this.zielperson_name,
              zielperson_ort: this.zielperson_ort,
              zielperson_telefon: this.zielperson_telefon,
              zielperson_strasse: this.zielperson_strasse,
              typ: "analog",
              pfad: result.ref.fullPath,
              nameFile: result.ref.name
            } as Einkaufszettel);

            console.log(einkaufszettel);
            this.commService.saveEinkaufszettel(einkaufszettel, this.user.uid).then(val => {

              this.loaderAnalog = false;
              this.successAnalog = true;
            });
          }
      }else{

      }

      // .saveFoto(path,this.titel,this.channelSelected.trim(),this.hausname).then(res=>{
      //    this.loading = false;
      //    this.eingabe = false;
      //    this.titel = "";
      //    this.loader = false;
      //    document.querySelector('ion-content').scrollToTop(500);
      //    this.channel = this.channelSelected.trim();
      //    this.changeChannel();
       //   console.log("Erfolgreich geladen");



      },
       err=>{
         console.log(err);
       });;
  }


  createScanner(){
    Quagga.init({
        inputStream: {
          constraints: {
            facingMode: 'environment' // restrict camera type
          },
          area: { // defines rectangle of the detection
            top: '40%',    // top offset
            right: '0%',  // right offset
            left: '0%',   // left offset
            bottom: '40%'  // bottom offset
          },
        },
        decoder: {
          readers: ['ean_reader'] // restrict code types
        },
      },
      (err) => {
        if (err) {
          this.errorMessage = `QuaggaJS could not be initialized, err: ${err}`;
        } else {
          Quagga.start();
          Quagga.onDetected((res) => {
          //  window.alert(`code: ${res.codeResult.code}`);

            if(res.codeResult.code==this.res_temp){

              return null;
            }else {
              this.eancode = res.codeResult.code;
              this.res_temp = res.codeResult.code;
              this.ean_detection = true;
              this.getEanArticle(res.codeResult.code);
            }

          })
        }
      });

  }

  ngOnInit(): void {



  }

}
