import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './services/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
     state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
try {
  console.log("kritischer part");
  this.authService.getUser().subscribe(result_2 => {
    let result: any;
    result = result_2;
    if (typeof result.verifiedProfil == "undefined") {
      this.router.navigate(['login'])
    }
    if(this.authService.isLoggedIn !== true) {
      this.router.navigate(['login'])
    }

  })
}
catch(error){
  console.log(error);
    this.router.navigate(['login'])
}

    return true;
  }


}
