import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EvernoteService {

  constructor(private http: HttpClient) {
  }

  getAuhorizeUrl() {
    const localUrl = 'https://europe-west3-besorgselfried.cloudfunctions.net/access_token_evernote';
    return this.http.get(localUrl);
  }

  /* Todo: Check authorization */


  connectionTest(token) {
    if(!token){
      return "no connection";
    }else {
      let localUrl = 'https://europe-west3-besorgselfried.cloudfunctions.net/access_token_evernote/connectiontest?token=' + token;
      return this.http.get(localUrl);
    }
     }
     createNode(title,token,notestring){
    console.log("token",token);
    //title="test";
    //notestring = "asdfölaksdjf";
       if(!title){
         title="Kein Einkaufszettelname angegeben";
       }
       let localUrl = 'https://europe-west3-besorgselfried.cloudfunctions.net/access_token_evernote/createnote?token='+token+'&titel='+title+'&inhalt='+notestring;

       return this.http.get(localUrl);

     }

  getAccessToken(oauth_secret, oauth_verifier, oauth_token) {
    let localUrl = 'https://europe-west3-besorgselfried.cloudfunctions.net/access_token_evernote/oauth?oauth_token='+oauth_token+'&oauth_verifier='+oauth_verifier+'&oauth_secret='+oauth_secret;
    return this.http.get(localUrl);
  }
}
