import { Component, OnInit } from '@angular/core';
import {Community} from '../shared/services/community';
import {Artikel} from '../shared/services/artikel';
import { AuthService } from '../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunityService} from '../services/community.service';

import { AngularFireStorage } from '@angular/fire/storage';
import {User} from '../shared/services/user';
import {Einkaufszettel} from '../shared/services/einkaufszettel';

import {EinkaufszettelAnalog} from '../shared/services/einkaufszettelAnalog';
import {UserGuard} from '../user.guard';
import {combineLatest, Observable} from 'rxjs';
import { of } from 'rxjs'; // creates an Observable with a raw value
import {map, switchMap} from 'rxjs/operators';
import {EvernoteService} from '../services/evernote.service';
@Component({
  selector: 'app-community-dashboard',
  templateUrl: './community-dashboard.component.html',
  styleUrls: ['./community-dashboard.component.css']
})
export class CommunityDashboardComponent implements OnInit {
analogList: any[];

  userObj: User;
  userVerified: boolean = false;
  firstName: string;
  userId: string;
  communityObj: Community;
  artikelObj: unknown[];
  einkaufszettelObj: Einkaufszettel[] = [];
  artikelListe: Artikel[];
  uid: string;
  users: User[] = [];
  firstnames: string[];
  lastnames: string[];
  userObserver;
  community_name: string;
  bilderList : any[] = [];
  analogListStatus: any[]=[];
  usersanalog: any[];
  ready: number =0;
  communityId: any;
  mobile:boolean=false;
  constructor(private evernoteservice: EvernoteService, private afStore: AngularFireStorage, private com: CommunityService, private auth: AuthService, private router: Router, private route: ActivatedRoute) {
    this.ready = 0;
    /* if the device is not ios hide the download button */
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      this.mobile = true;

    }else{
      // false for not mobile device
      this.mobile=false;
    }
//Datenabruf start
    this.auth.getUser().pipe(
      switchMap(val=> {
        const userData= val;
        return combineLatest([this.com.checkCommunityRole(val["communityId"], val["uid"]),this.com.getCommById(val["communityId"]), this.com.getAnalogEinkaufszettel(val["communityId"]), this.auth.getUser(), this.com.getAllCommunityUser(val["communityId"]), this.com.getEinkaufszettel(val["communityId"])]).pipe(map(commData=>({userData, commData})))
      })).subscribe(({userData, commData}) =>{
// Datenabruf fertig
      console.log(commData);
      this.uid = userData["uid"];
      this.communityId = userData["communityId"];
       //this.communityRole = commData[0][0]["role"];
this.userObj = commData[3];
console.log(this.users);
if(commData[3]["gdpr"]){

}
else{
  this.userObj.gdpr=0;
}
console.log(this.userObj.gdpr);
this.communityObj = commData[1];

         this.getCommUsers(commData[4]);
       this.getEinkaufszettel(commData[5]);
  this.getAnalogEinkaufszettel(commData[2]);


    });



    this.communityObj = ({
      titel: "",
      uid: "",
      admin: "",
    } as Community);





  }


  ngOnInit(): void {


}

acceptGDPR(uid){
   // this.userObj.gdpr = 1;
  this.auth.acceptGDPR(uid);
}

removeUser(uid){
  var tmp = confirm("User aus der Community entfernen?");
  if(tmp){
    this.com.removeUser(this.userObj.communityId, uid)

  }

}

takeEinkauf(uid){
  if(confirm("Einkauf übernehmen?")){
    this.com.takeEinkauf(this.userObj.communityId,this.userObj.uid, uid);

  }
}
  takeEinkaufAnalog(uid, zettelid){

    if(confirm("Einkauf übernehmen?")){
      this.com.takeEinkaufAnalog(this.userObj.communityId,this.userObj.uid, uid, uid).then(val=>{
      //  location.reload();
      });

    }
  }
getAnalogEinkaufszettel(val){










       if(val){

         this.analogList = [];
         this.bilderList = [];
         this.usersanalog = [];

         for(let zettel of val){
            let tmpZettel: EinkaufszettelAnalog = zettel;
            let userid = tmpZettel.userId;
            this.auth.getUserByUid(userid).subscribe(value => {
            this.usersanalog.push(value);
           });

           if(tmpZettel.status == 1 || tmpZettel.status == undefined) {

             this.analogList.push(tmpZettel.customIdName);
             this.analogListStatus.push(tmpZettel.status);
             console.log(this.analogListStatus);
             console.log(this.analogList);
             console.log(tmpZettel.path);
             let ref = this.afStore.ref(`files/${this.communityId}/${tmpZettel.path}`);
             ref.getDownloadURL().subscribe(val => {
               this.bilderList.push(val);



             });
           }
         }
         this.ready = 1;


       }

}

whatsappexport(artuid){
  this.com.getEinkaufszettelById(this.userObj.communityId, artuid).subscribe(result=>{


    let artikelstring = "";
    for(let i=0; i<result["artikelListe"].length;i++){
      artikelstring = artikelstring+result["artikelListe"][i]["name"]+" Marke: "+result["artikelListe"][i]["brand"]+'\n';
    }

      var url = "https://api.maytapi.com/api/78b83875-d7be-4c1b-b948-684ff4568790/8702/sendMessage";

      var xhr = new XMLHttpRequest();
      xhr.open("POST", url);

      xhr.setRequestHeader("accept", "application/json");
      xhr.setRequestHeader("x-maytapi-key", "5d3a4ccc-05d6-436f-9a28-42d85185d207");
      xhr.setRequestHeader("Content-Type", "application/json");

      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4) {
          console.log(xhr.status);
          console.log(xhr.responseText);
        }};
artikelstring = "Dein Einkaufszettel: "+artikelstring;
      var data2 = {"to_number":"+4917692601589","type":"text","message": artikelstring};
      console.log(data2);
      xhr.send(JSON.stringify(data2));
      alert("Einkaufszettel wurde dir per Whatsapp gesendet");


  })

}


evernoteexport(artuid){
    this.com.getEinkaufszettelById(this.userObj.communityId, artuid).subscribe(result=>{


      let artikelstring = "";
      for(let i=0; i<result["artikelListe"].length;i++){
        artikelstring = artikelstring+result["artikelListe"][i]["name"]+" Marke: "+result["artikelListe"][i]["brand"]+'\n';
      }
      this.evernoteservice.createNode(result["name"],this.userObj.evernote_token, artikelstring).subscribe(result2=>{
      alert("Einkaufszettel wurde zu Evernote exportiert");
      })

    })

}


  getEinkaufszettel(data2){
this.einkaufszettelObj =[];
      if(data2.length>0){
        for(let tmp of data2){
          let zettelObj:Einkaufszettel = tmp;
          this.auth.getUserByUid(zettelObj.userId).subscribe(val=>{
              zettelObj.userObj = val;
              zettelObj.gps = false;
              this.einkaufszettelObj.push(zettelObj);
          });
        }
      }

}

  activateUser(userId){
      this.com.activateUser(this.userObj.communityId,userId);
  }


  make_captain(userId){

    this.com.make_captain(this.userObj.communityId,userId);
  }
  make_community(userId){

    this.com.make_community(this.userObj.communityId,userId);
  }



  getCommUsers(val){
              this.users = [];

          if(val){
            for(let tmp of val){
              let obj:User = tmp;
              if(this.userObj.uid == obj.uid){
                this.userObj.communityRole = +obj.role;
              }
              this.com.getUser(obj.uid).subscribe(val=>{

                obj.userObj = val;
                this.users.push(obj);

              });
            }
          }
          console.log(this.users);

  }







}
