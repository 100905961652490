import { Injectable } from '@angular/core';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/firestore';
import {map, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {MapsAPILoader} from '@agm/core';
import {fromPromise} from 'rxjs/internal-compatibility';
import {HttpClient} from '@angular/common/http';


declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private geocoder: any;
  constructor(private http: HttpClient, private afs:AngularFirestore, private mapLoader: MapsAPILoader) { }

  private initGeocoder() {
    console.log('Init geocoder!');
    this.geocoder = new google.maps.Geocoder();
  }

  getEinkaufszettelGlobal(){
  return this.afs.collection(`einkaufszettel_global`, ref => ref.where('status', '==', 1)).valueChanges();
}
  getEinkaufszettelGlobalUnique(id){
    return this.afs.collection(`einkaufszettel_global` , ref => ref.where('uid', '==', id)).valueChanges();
  }

  getGeocodeFree(query) {

if(query.includes("undefined")){

  let observable = of('message 1', 'message 2','message 3');
  return observable;

}

    const localUrl = 'http://api.positionstack.com/v1/forward?access_key=3750e7c9a3b27602741a596e9d78d762&query='+query+'&output=json&fields =results.latitude,results.longitude&limit=1&country=DE';
    return this.http.get(localUrl);
  }


createGeosforAllUser(){
this.afs.collection("user").get().subscribe(result=>{
    let users = [];
    let temp: any;
  result.forEach((doc)=>{
    temp =  doc.data()["userObj"];
          if(!temp){
            temp = doc.data()
          }
          users.push(temp);
  })
for(let i=0; i<users.length; i++){


  console.log(users[i]);
  this.getGeocodeFree(users[i]["zip"]+" "+users[i]["address2"]).subscribe(resulti=>{
    var geo: any;
    geo = {};
    console.log(resulti);
    if(resulti=="message 1" || resulti == "message 2" || resulti == "message 3"){
      geo.lat = "nicht gefunden";
      geo.lng ="nicht gefunden";
    }
    else {
      if (resulti["data"]["length"] > 0) {


        geo.lat = resulti["data"][0]["latitude"];
        geo.lng = resulti["data"][0]["longitude"];
      } else {
        geo.lat = "nicht gefunden";
        geo.lng = "nicht gefunden";
      }
    }
  users[i].geo = geo;

    console.log(users[i]);
  const userRef: AngularFirestoreDocument<any> = this.afs.doc(`user/${users[i].uid}`);
  userRef.set(users[i], {})

  })


}





})


 }
  private waitForMapsToLoad(): Observable<boolean> {
    if(!this.geocoder) {
      return fromPromise(this.mapLoader.load())
        .pipe(
          tap(() => this.initGeocoder()),
          map(() => true)
        );
    }
    return of(true);
  }

  geocodeAddress(location: string): Observable<any> {
    console.log('Start geocoding!');
    return this.waitForMapsToLoad().pipe(
      // filter(loaded => loaded),
      switchMap(() => {
        return new Observable<any>(observer => {
          this.geocoder.geocode({'address': location}, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
              console.log('Geocoding complete!');
              observer.next({
                lat: results[0].geometry.location.lat(),
                lng: results[0].geometry.location.lng()
              });
            } else {
              console.log('Error - ', results, ' & Status - ', status);
              observer.next({ lat: 0, lng: 0 });
            }
            observer.complete();
          });
        })
      })
    )
  }









  }


