import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {faStar} from '@fortawesome/free-solid-svg-icons';

import { UserService } from '../services/user.service';

import { Router, ActivatedRoute, ParamMap } from '@angular/router';


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  faStar = faStar;
  startAnimation = false;
  constructor(private router:Router, private user:UserService) {


   }

   showInfo(){
     this.startAnimation = true;

   }

  ngOnInit(): void {
  }

}
