<div *ngIf="!ready" class="container">

<div class="d-flex p-2  justify-content-center"><img class="spinner" src="https://besorgselfriede.org/assets/img/logomithintergrund.png" /></div>
  <div class="d-flex p-2  justify-content-center">
    <img class="img-responsive" style=" max-width: 5%; max-height:5%;
  width:100%; height:100%" src="assets/img/ci_loader.gif"/>
</div>
</div>

<div *ngIf="ready">
<section class="services pt-100 pb-50" id="services">
  <div class="container">

      <div class="col-xl-6 mx-auto text-center">
        <div class="section-title mb-2">
          <p>Was kann ich alles machen?</p>
          <h4>Dashboard</h4>
        </div>
      </div>
      <div style="margin-bottom:5px;">

      </div>
    <div class="row mt-2">
      <div class="col-lg-4 col-md-6">
        <!-- Single Service -->
        <a class="text-decoration-none"  [routerLink]="['/einkaufszettel']">
        <div class="single-service">
          <i class="fas fa-users"></i>
          <h4 style="text-transform: none;">Einkaufszettel - Übersicht </h4>
          <p>Alle aktuell offenen Einkaufszettel</p><br>
        </div>
        </a>
      </div>

     <div class="col-lg-4 col-md-6">
        <!-- Single Service -->
        <a class="text-decoration-none" [routerLink]="['/addEinkaufszettel']">
        <div class="single-service">
          <i class="fas fa-shopping-bag"></i>
          <h4 style="text-transform: none;">Einkaufszettel anlegen </h4>
          <p>Lege einen neuen Einkaufszettel an </p><br>
        </div>
        </a>
      </div>



      <div class="col-lg-4 col-md-6">
        <!-- Single Service -->
        <a class="text-decoration-none" [routerLink]="['/myShopping']">
          <div class="single-service">
            <i class="fas fa-cart-arrow-down"></i>
            <h4 style="text-transform: none;">Meine Einkaufszettel </h4>
            <p>Alle Einkaufszettel die du übernommen hast </p>
          </div>
        </a>
      </div>





      <div class="col-lg-4 col-md-6" >
        <!-- Single Service -->
        <a class="text-decoration-none" [routerLink]="['/print']">
        <div class="single-service">
          <i class="fas fa-print"></i>
          <h4 style="text-transform: none;">Einkaufszettel ausdrucken</h4>
          <p>Drucke Einkaufszettel für private Freunde, Bedürftige etc.</p>
        </div>
        </a>
      </div>

      <div class="col-lg-4 col-md-6" >
        <!-- Single Service -->
        <a class="text-decoration-none" [routerLink]="['/user']">
          <div class="single-service">
            <i class="fas fa-user-cog"></i>
            <h4>Profileinstellungen</h4>
            <p>Persönliche Daten, Benachrichtigungen...</p>

          </div>
        </a>
      </div>





    </div>
  </div>

</section>
</div>
