import {Component, OnInit} from '@angular/core';
import {User} from '../shared/services/user';
import {ActivatedRoute} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {UserService} from '../services/user.service';
import {EvernoteService} from '../services/evernote.service';
import {Url, URL} from 'url';
import {concatMap, map} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {CommunityService} from '../services/community.service';
import {GlobalService} from '../services/global.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  global: any;
  userObj: User;
  message: string = '';
  neweinkaufszettelmail: any;
  statuschangemail: any;
  take_einkaufszettelmail: any;
  einkaufszettel_zuruecklegen: any;
  einkaufszettel_erledigen: any;
  evernote_check = false;
  alexa_check = false;
  google_check = false;
  subscription: any;
  subscription1: any;
  ready: number;
  radius_display: string;

  constructor(private globalservice: GlobalService, private evernotesrvice: EvernoteService, private com: CommunityService, private route: ActivatedRoute, private us: UserService, private auth: AuthService) {
    this.ready = 0;
    this.subscription = this.auth.getUser();

    if (this.auth.isLoggedIn) {
      this.subscription.pipe(
        concatMap(val => {
          const userData = val;
          return combineLatest([this.evernotesrvice.connectionTest(userData['evernote_token']), this.com.checkAlexaConnection(userData['uid']), this.com.checkGoogleConnection(userData['uid'])]).pipe(map(checkData => ({
            userData,
            checkData
          })));
        })).subscribe(({userData, checkData}) => {


        console.log('evernoteCheck', checkData);
        if (checkData[0]['is_connected'] == 'true') {
          this.evernote_check = true;
        } else {
          this.evernote_check = false;
        }
        console.log('check_data', checkData[1].length);
        this.ready = 1;
        if (checkData[1].length !== 0) {
          this.alexa_check = true;
        }
        if (checkData[2].length !== 0) {
          this.google_check = true;
        }

      });
    }


    /* Todo: Alexa Verbindungsanzeige, Google-Home-Verbindungs-Anzeige, Open-Food-Verbindungsanzeige */


    this.auth.getUser().subscribe(data => {
      if (data != undefined) {
        this.userObj = data;
        if (this.userObj.mail_einkauf_erledigt == 1) {
          this.einkaufszettel_erledigen = 'checked';

        } else {
          this.einkaufszettel_erledigen = '';
        }
        if (this.userObj.mail_neuer_einkaufszettel == 1) {

          this.neweinkaufszettelmail = 'checked';
          this.radius_display = (this.userObj.radius/ 1000).toString();
        } else {
          this.radius_display = "0";
          this.neweinkaufszettelmail = '';
        }
        if (this.userObj.mail_statuswechsel_einkaufszettel == 1) {
          this.statuschangemail = 'checked';

        } else {
          this.statuschangemail = '';
        }
        if (this.userObj.mail_take_einkaufszettel == 1) {
          this.take_einkaufszettelmail = 'checked';

        } else {
          this.take_einkaufszettelmail = '';
        }

        if (this.userObj.mail_einkaufszettel_zuruecklegen == 1) {
          this.einkaufszettel_zuruecklegen = 'checked';

        } else {
          this.einkaufszettel_zuruecklegen = '';
        }


      }
    });
  }
  changeneweinkaufszettel(){
    this.radius_display = (5).toString();
  }
  async connectToEvernote() {

    this.evernotesrvice.getAuhorizeUrl().subscribe(results => {

      this.userObj.evernote_secret = results['secret'];
      console.log('results', results);
      console.log('secret', results['secret']);
           this.auth.updateUserEvernote(this.userObj).then(test=>{
        window.location.href = results['url'];
        })
    });


  }

  refreshUser(userObj, newartikel, statuschange, take_einkaufszettel, einkaufszettel_zrueck, einkauf_erledigen, radius) {

    let radius_value = 0;


    if (einkauf_erledigen.checked) {
      userObj.mail_einkauf_erledigt = 1;
    } else {
      userObj.mail_einkauf_erledigt = 0;
    }


    if (einkaufszettel_zrueck.checked) {
      userObj.mail_einkaufszettel_zuruecklegen = 1;
    } else {
      userObj.mail_einkaufszettel_zuruecklegen = 0;
    }


    if (newartikel.checked) {
      userObj.mail_neuer_einkaufszettel = 1;
      radius_value = radius.value*1000;
    } else {
      radius = 0;
      userObj.mail_neuer_einkaufszettel = 0;
    }
    if (statuschange.checked) {
      userObj.mail_statuswechsel_einkaufszettel = 1;
    } else {
      userObj.mail_statuswechsel_einkaufszettel = 0;
    }
    if (take_einkaufszettel.checked) {
      userObj.mail_take_einkaufszettel = 1;
    } else {
      userObj.mail_take_einkaufszettel = 0;
    }


    userObj.radius = radius_value;

    this.globalservice.geocodeAddress(userObj['zip'] + userObj['address2']).subscribe(result => {
        console.log(result);
        userObj.geo = result;
        this.auth.updateUser(userObj);
        alert('Userdaten aktualisiert');
      }
    );


  }


  removeMessage() {
    this.message = '';
  }

  ngOnInit(): void {
  }

}
