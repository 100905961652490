
<div *ngIf="ready==0" class="container">

  <div class="d-flex p-2  justify-content-center"><img class="spinner" src="https://besorgselfriede.org/assets/img/logomithintergrund.png" /></div>
  <div class="d-flex p-2  justify-content-center">
    <img class="img-responsive" style=" max-width: 5%; max-height:5%;
  width:100%; height:100%" src="assets/img/ci_loader.gif"/>
  </div>
</div>
<div *ngIf="ready==1" class="container">

<div class="text-center">
<div class="pb-5 justify-content-center">
  <div class="container-fluid" style="margin-top:5px" *ngIf="message != ''">
    {{message}}
  </div>
  <div class="row justify-content-center">
    <div class="col-auto">
  <div class="table-responsive" style="margin-top:5px" *ngIf="userObj">
<table class="table-borderless table text-white text-right" style="
    width: 41rem;
">
  <tr><td class="text-left" colspan="3"><h5>Persönliche Daten</h5></td></tr>
<tr>
     <td><label>Vorname:</label></td>
  <td><input type="text" class="form-control" placeholder="Vorname *"  [(ngModel)]="userObj.firstname" /></td>
</tr>
    <tr>
      <td><label>Nachname:</label></td>
     <td> <input type="text" class="form-control" placeholder="Nachnamee *" [(ngModel)]="userObj.lastname" /></td>
    </tr>
    <tr>
    <td><label>Anschrift:</label></td>
      <td><input type="text" class="form-control" placeholder="Adresse *" [(ngModel)]="userObj.address1" /></td>
    </tr>
<tr>
     <td> <label>PLZ:</label></td>
  <td><input type="text" class="form-control" placeholder="PLZ*" [(ngModel)]="userObj.zip" /></td>
    </tr>

<tr>
  <td><label>Ort:</label></td>
  <td><input type="text" class="form-control" placeholder="Ort *" [(ngModel)]="userObj.address2" /></td>
    </tr>
<tr>
      <td><label>Telefon:</label></td>
  <td><input type="text" class="form-control" placeholder="Telefon*" [(ngModel)]="userObj.phone" /></td>
</tr>

     <tr><td colspan="3" class="text-left"><h5>E-Mail-Benachrichtigungen</h5></td></tr>
  <tr><td></td><td class="text-left"><input (change)="changeneweinkaufszettel()"  [checked]="neweinkaufszettelmail"  #newartikel type="checkbox" class="from-control"/>  Neue Einkaufszettel in meiner Nähe: &nbsp; <select  [(ngModel)]='radius_display' #radius>
    <option value="5">+ 5km </option>
    <option value="10">+ 10km </option>
    <option value="20">+ 20km </option>
    <option value="50">+ 50km </option>
    <option value="100">+ 100km </option>
  </select></tr>
  <tr><td></td><td class="text-left"><input  [checked]="statuschangemail" #statuschange type="checkbox" class="from-control"/>  Statusänderung in meinen Einkaufszetteln </tr>
  <tr><td></td><td class="text-left"><input  [checked]="take_einkaufszettelmail" #take_einkaufszettel type="checkbox" class="from-control"/>  Übernehmen von Einkaufszetteln </tr>
  <tr><td></td><td class="text-left"><input  [checked]="einkaufszettel_zuruecklegen" #einkaufszettel_zurueck type="checkbox" class="from-control"/>  Zurücklegen von Einkaufszetteln </tr>
  <tr><td></td><td class="text-left"><input  [checked]="einkaufszettel_erledigen" #einkaufszettel_erledigt type="checkbox" class="from-control"/>  Erledigen von Einkaufszetteln </tr>


  <tr><td colspan="3" class="text-left"><h5>Drittanbieter-Verknüpfungen <small>(Beta)</small></h5></td></tr>
  <tr>
    <td>Evernote: </td>
    <td  *ngIf="!evernote_check" class="text-left"><div class="form-group" (click)="connectToEvernote()">
    <button style="font-size:0.7em"  class="btn btn-primary btn-lg btn-bloc btn-responsive">
      <i class="fab fa-evernote">  </i>    Verbindung zu Evernote herstellen </button>
      </div></td>

    <td *ngIf="evernote_check" class="text-left"><div  class="form-group">
      <i class="fab fa-evernote"></i><span> &nbsp;  </span> <span>Verbindung zu Evernote hergestellt </span><i style ="color: green;"class="fas fa-check-circle"></i>
    </div></td>
  </tr>
  <tr>
    <td>Google:</td>
    <td  *ngIf="!google_check" class="text-left"><div class="form-group" (click)="connectToEvernote()">

      <i class="fab fa-google"></i><span> &nbsp;   </span><span>Kein verknüpftes Google-Assistant-Konto </span><i style="color:red;"class="fas fa-times"></i>

    </div></td>

    <td *ngIf="google_check" class="text-left"><div  class="form-group">
      <i class="fab fa-google"></i><span> &nbsp;  </span> <span>Google-Assistant-Konto erfolgreich verknüpft </span><i style ="color: green;"class="fas fa-check-circle"></i>
    </div></td>
  </tr>

  <tr>
    <td>Amazon</td>
    <td  *ngIf="!alexa_check" class="text-left"><div class="form-group" (click)="connectToEvernote()">

      <i class="fab fa-amazon"></i><span> &nbsp;  </span><span>Kein verknüpftes Amazon Alexa - Konto </span><i style="color:red;"class="fas fa-times"></i>

    </div></td>

    <td *ngIf="alexa_check" class="text-left"><div  class="form-group">
      <i class="fab fa-amazon"></i><span> &nbsp;  </span><span>Amazon-Alexa-Konto erfolgreich verknüpft </span><i style ="color: green;"class="fas fa-check-circle"></i>
    </div></td>
  </tr>

</table>





    <div class="form-group">
      <button  class="btn btn-primary btn-lg btn-bloc"
               value="Daten aktualisieren" (click)="refreshUser(userObj, newartikel, statuschange, take_einkaufszettel, einkaufszettel_zurueck, einkaufszettel_erledigt, radius)">
        <i class="fas fa-redo"></i>  Daten aktualisieren
      </button>
    </div>


</div>
</div>
</div>
</div>
</div>
