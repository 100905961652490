import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class EinkaufszettelService {

  constructor(private afs:AngularFirestore) { }



  getEinkaufszettelByStatusAndUser(status, uid){

    let query =  this.afs.collection(`einkaufszettel_global`, ref => ref.where('einkaeufer', '==', uid));
    return query.valueChanges();
  }

  resetShopping(uid){
    return this.afs.doc(`einkaufszettel_global/${uid}`).set({
      einkaeufer:"",
      status: 1,
      },{merge:true})
  }

  finishShopping(uid, preis){
    return this.afs.doc(`einkaufszettel_global/${uid}`).set({
      preis: preis,
      status: 3,
    },{merge:true})
  }

  takeEinkauf(zettelId, einkaeuferId) {


    return this.afs.doc(`einkaufszettel_global/${zettelId}`).set({
      einkaeufer: einkaeuferId,
      status: 2,
    },{merge:true})
  }


}

