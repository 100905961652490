<div *ngIf="ready==0" class="container">

  <div class="d-flex p-2  justify-content-center"><img class="spinner" src="https://besorgselfriede.org/assets/img/logomithintergrund.png" /></div>
  <div class="d-flex p-2  justify-content-center">
    <img class="img-responsive" style=" max-width: 5%; max-height:5%;
  width:100%; height:100%" src="assets/img/ci_loader.gif"/>
  </div>
</div>
<div *ngIf="ready==1">

<div class="container"  *ngIf="!userVerified">
       <div class="col-md-12 order-md-1 pb-4" style="margin-top:5px">
         <h4 class="mb-3 text-center">Was wir unbedingt noch von dir wissen müssen</h4>
            <div class="col-md-9" style="background-color:#A4DD5F;width:100%;text-align:right;">
              80% geschafft
            </div>
         <form #userForm="ngForm" (ngSubmit)="onSubmit(userForm)" style="margin-top:10px;" class="needs-validation mb-4" novalidate>
           <div class="row">
             <div class="col-md-6 mb-3">
               <label for="firstName1">Vorname</label>
               <input type="text" [(ngModel)]="userObj.firstname"   class="form-control" name="firstname" placeholder="Vorname" required
                      #firstname="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && firstname.invalid }">
               <div class="invalid-feedback">
                 Bitte gültigen Vornamen angeben.
               </div>


             </div>
             <div class="col-md-6 mb-3">
               <label for="lastName">Nachname</label>
               <input type="text" [(ngModel)]="userObj.lastname" class="form-control" name="lastName" placeholder="Nachname" value="" required
               #lastName="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && lastName.invalid }">
               <div class="invalid-feedback">
                 Bitte gültigen Nachnamen angeben.
               </div>
             </div>
           </div>


           <div class="mb-3">
             <label for="address">Strasse & Hausnummer</label>
             <input type="text" [(ngModel)]="userObj.address1"   class="form-control" name="address1" placeholder="Banhofstrasse 24" required
             #address1="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && address1.invalid }">
             <div class="invalid-feedback">
               Bitte eine gültige Adresse
             </div>
           </div>

           <div class="mb-3">
             <label for="address2">Ort <span class="text-muted"></span></label>
             <input type="text" [(ngModel)]="userObj.address2"   class="form-control" name="address2" placeholder="Ort" required
                    #address2="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && address2.invalid }">
             <div class="invalid-feedback">
               Bitte gültigen Ort angeben
             </div>
           </div>
           <div class="mb-3 ">
             <label for="zip">Postleitzahl</label>
             <input type="text" [(ngModel)]="userObj.zip"  class="form-control" name="zip" placeholder="PLZ" required
             #zip="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && zip.invalid }">
             <div class="invalid-feedback">
               Bitte gültige Postleitzahl angeben
             </div>
           </div>
           <div class="mb-3 ">
             <label for="phone">Telefonnummer</label>
             <input type="text" [(ngModel)]="userObj.phone"  class="form-control" name="phone" placeholder="Festnetz/Handynummer" required
                    #phone="ngModel"  [ngClass]="{ 'is-invalid': userForm.submitted && phone.invalid }">
             <div class="invalid-feedback">
               Bitte gültige Telefonnummer angeben
              </div>
           </div>

           <hr class="mb-4">
           <button class="btn btn-primary btn-lg btn-block" type="submit">Profil vervollständigen und ab zur guten Tat</button>
         </form>
       </div>
</div>
  <div class="container" *ngIf="!mailVerified">

    <h4 class="pt-5 mb-3 text-center">Bitte bestätige deine Mail-Adresse, dann können wir endlich starten.
    </h4>
    <p class="text-center">Klicke hier: <a class="btn btn-success"(click)="sendVerificationMail()">E-Mail erneut senden</a> ,um dir die Verifizierungs-Mail erneut zusenden zu lassen</p>
<h4 class="pt-5 text-center" *ngIf="mailsent">Die Bestätigungs-Mail wurde dir erfolgreich zugestellt, klicke auf den Link in der E-Mail um dich erfolgreich zu verifizieren.</h4>
  </div>
<div class="container" *ngIf="userVerified && mailVerified">
                <div class="row" style="margin-top:20px;">

                  <div class="col-md-12 text-center">
                      <h3>Du - die Community - und Elfriede </h3>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <a class="text-decoration-none">
                    <div class="single-service">
                      <i class="fas fa-users"></i>
                      <h4>Community anlegen </h4>

                        <input type="text"  #communityName require  class="form-control" placeholder="" value="" required>
                               <br><br>
                               <input type="submit" class="btn btn-primary btn-lg btn-bloc"
                                value="Community anlegen"
                                (click)="createCommunity(communityName.value)">
                    </div>
                    </a>
                  </div>
                  <div class="col-lg-6 col-md-6">
                    <!-- Single Service -->
                    <a class="text-decoration-none">
                    <div class="single-service">
                      <i class="fas fa-users"></i>
                      <h4>Community beitreten  </h4>
                      <select class="form-control"   #communitySelection require >
                        <option value=""> Bestehende Community auswählen</option>
                        <option  *ngFor="let comm of communityList" value="{{comm.uid}}">
                          {{comm.titel}}
                        </option>
                      </select>
                      <br><br>
                      <input type="submit" class="btn btn-primary btn-lg btn-bloc"
                       value="Community beitreten"
                       (click)="enterCommunity(communitySelection.value)">

                    </div>
                    </a>
                  </div>
                </div>

              <div class="col-12 text-center">
                <img class="img-responsive" style="max-height:400px;" src="assets/img/logomithintergrund.png">
              </div>
</div>
</div>
