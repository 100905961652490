<div *ngIf="ready==0" class="container">

  <div class="d-flex p-2  justify-content-center"><img class="spinner" src="https://besorgselfriede.org/assets/img/logomithintergrund.png" /></div>
  <div class="d-flex p-2  justify-content-center">
    <img class="img-responsive" style=" max-width: 5%; max-height:5%;
  width:100%; height:100%" src="assets/img/ci_loader.gif"/>
  </div>
</div>
<div *ngIf="ready==1">
<div class="container pb-5 pt-3 justify-content-center">
  <div class="row" style="color:black;">
    <div class="col-sm-12">
      <h3 style="">Offene Einkäufe</h3>
    </div>

<p class="col-sm-12" style="color: white">Digital:</p>
    <div class="col-sm-12 pb-4" style="color:white" *ngIf="einkaufszettelObj.length==0">Keine offenen Einkaufszettel</div>
  <div class="col-sm-6 mt-4" *ngFor="let art of einkaufszettelObj; let i = index">

    <div class="card" >



      <div  style="color:black">
        <div  style="color:black">
          <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
            <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
            <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b>  {{art.userObj.firstname}} {{art.userObj.lastname}}</div>
            <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.userObj.zip}} {{art.userObj.address2}} {{art.userObj.address1}} </div>
            <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
            <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
            <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
          </div>
          <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
            <div class="row justify-content-md-center">{{art.name}} </div>
            <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
            <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
            <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
            <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
            <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span><span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span></div>
          </div>
        </div>
             <ul class="list-group list-group-flush">
                <li style="display: inline" class="list-group-item" *ngFor="let art2 of art.artikelListe;let a = index" >
                  {{a+1}}. {{art2.name}} (Menge: {{art2.menge}} {{art2.einheit}}) (Bemerkung: {{art2.bemerkung}})
                  <div class="col-sm">
                    <img style="width:30px" src={{art2?.image_url}} >
                  </div>
                 </li>
                   <li class="list-group-item">
                   <div class="row">
                     <div class="col-sm-10">
                       Ausgaben: <input type="text" #preis1  size=2 (change)="preis(preis1.value, 1)"><span style="font-size:20px;">,</span><input size=2 type="text"  #preis2 (change)="preis(preis2.value, 2)" >€
                     </div>
                   </div>
                   </li>
               <li class="list-group-item">
                 <div class="row mt-2">
                   <div class="col btn btn-outline" (click)="finishShopping(art.uid)">
                     <i class="fas fa-check fa"></i> Einkaufszettel als erledigt markieren
                   </div>
                   <div class="col btn btn-outline ml-2" (click)="resetShopping(art.uid)">
                     <i class="fas fa-trash"></i> Einkaufszettel zurücklegen
                   </div>
                   <div class="col-1" style="color:green; cursor:pointer">
                     <a triggers="click mouseenter hover" [autoClose]="true" placement="bottom" ngbTooltip="Einkaufszettel zu Evernote exportieren"><span *ngIf="userObj.evernote_token" (click)="sendToEvernote(art)"><i class="fab fa-evernote"></i></span></a>
                   </div>

                 </div>

               </li>
             </ul>

        </div>

    </div>
  </div>



  <p class="col-sm-12 pt-4" style="color: white">Analog:</p>
  <div class="col-auto " style="color:white" *ngIf="einkaufszettelObjAnalog.length==0">Keine offenen Einkaufszettel</div>

  <div class="col-auto mt-4" style="color:black" *ngFor="let art of einkaufszettelObjAnalog; let i=index" >

    <div class="card">

        <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
          <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
          <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.userObj.firstname}} {{art.userObj.lastname}}</div>
          <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.userObj.zip}} {{art.userObj.address2}} {{art.userObj.address1}} </div>
          <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
          <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
          <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
        </div>
        <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
          <div class="row justify-content-md-center">{{art.name}} </div>
          <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
          <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
          <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
          <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
          <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span><span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span></div>
        </div>

            <img class="img-fluid" src="{{art.url}}" >
      <li class="list-group-item" style="color: black">
        <div class="row">
          <div class="col-sm-8">
            Ausgaben: <input type="text" size=2 [(ngModel)]="price1"><span style="font-size:20px;">,</span><input size=2 type="text" [(ngModel)]="price2">€
          </div>
          </div>
      </li>
      <li class="list-group-item">
        <div class="row mt-2">
          <div class="col btn btn-outline" (click)="finishShopping(art.uid)">
            <i class="fas fa-check fa"></i> Einkaufszettel als erledigt markieren
          </div>
          <div class="col btn btn-outline ml-2" (click)="resetShopping(art.uid)">
            <i class="fas fa-trash"></i> Einkaufszettel zurücklegen
          </div>

        </div>

      </li>
    </div>

  </div>
  </div>
  <hr>
  <div class="row" style="color:black;">
    <div class="col-sm-12">
      <h3 style="">Erledigte Einkäufe</h3>

    </div>
    <p  class="col-sm-12" style="color:white">Digital: </p>
    <div class="col-sm-12 pb-4" style="color:white" *ngIf="einkaufszettelObjFinish.length==0">Keine erledigten Einkaufszettel</div>
    <br>
  <div class="col-sm-6 mt-4" *ngFor="let art of einkaufszettelObjFinish; let i = index">

        <div class="card"   >
          <div  style="color:black">
            <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
              <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
              <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.userObj.firstname}}</div>
              <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.userObj.zip}} {{art.userObj.address2}} </div>
              <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
              <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
              <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
            </div>
            <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
              <div class="row justify-content-md-center">{{art.name}} </div>
              <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
              <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
              <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
              <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
              <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span><span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span></div>
            </div>
          </div>     <ul class="list-group list-group-flush">
                 <li class="list-group-item">
                   <div class="row">
                     <div class="col-sm-8">
                       Ausgaben: {{art.preis}}€
                   </div>
                   </div>
                 </li>
             </ul>

        </div>
    </div>

    <div style="color:white"  class="col-sm-12 pt-4">Analog:</div>
    <p class="col-sm-12 pb-4" style="color:white" *ngIf="einkaufszettelObjFinishAnalog.length==0">Keine erledigten Einkaufszettel</p>

    <div class="col-sm-6 mt-4" *ngFor="let art of einkaufszettelObjFinishAnalog; let i = index">

      <div class="card"   >
        <div  style="color:black">
          <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
            <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
            <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.userObj.firstname}}</div>
            <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.userObj.zip}} {{art.userObj.address2}} </div>
            <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
            <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
            <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
          </div>
          <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
            <div class="row justify-content-md-center"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
            <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
            <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
            <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
            <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
            <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
          </div>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item">
            <div class="row">
              <div class="col-sm-8">
                Ausgaben: {{art.preis}}€
              </div>
            </div>
          </li>
        </ul>

      </div>
      <img class="img-fluid" src="{{art.url}}" >
    </div>




  </div>
</div>

</div>
