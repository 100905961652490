import {Component, OnInit} from '@angular/core';

import {Community} from '../shared/services/community';
import {Artikel} from '../shared/services/artikel';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CommunityService} from '../services/community.service';
import {User} from '../shared/services/user';
import {Einkaufszettel} from '../shared/services/einkaufszettel';
import {EinkaufszettelAnalog} from '../shared/services/einkaufszettelAnalog';
import {AngularFireStorage} from '@angular/fire/storage';
import {catchError, concatMap, flatMap, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {combineLatest, Observable, of} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {EinkaufszettelService} from '../services/einkaufszettel.service';
import {EvernoteService} from '../services/evernote.service';


@Component({
  selector: 'app-my-shopping-tasks',
  templateUrl: './my-shopping-tasks.component.html',
  styleUrls: ['./my-shopping-tasks.component.css']
})
export class MyShoppingTasksComponent implements OnInit {
  userObj: any;
  userVerified: any;
  communityId: string;
  einkaufszettelObj: any[] = [];
  shoppinglist: any[] = [];
  einkaufszettelObjFinish: any[] = [];
  einkaufszettelObjAnalog: any[] = [];

  einkaufszettelall: any[];
  einkaufszettelalluser: any[];


  einkaufszettelObjFinishAnalog: any[] = [];
  price1: number;
  price2: number;
  counter = 0;
  message: string;
  messageAnalog: string;
  analogList: any[] = [];
  analogListStatus: any[] = [];
  bilderList: any[] = [];
  bilderListFinish: any[] = [];
  usersanalog: any[] = [];
  usersanalogfinish: any[] =[];
  usersanalogtemp: any = [];
  usersdigital: any= [];
  usersdigitalfinish: any=[];
  ready: number = 0;

  constructor(private eservice: EinkaufszettelService, private auth: AuthService, private comm: CommunityService, private afStore: AngularFireStorage, public afs:AngularFirestore, private evernote: EvernoteService) {

    this.ready = 0;
    this.auth.getUser().pipe(
      switchMap(user => {


        this.userObj = user;
        this.userVerified = this.userObj.verifiedProfil;
        this.communityId = this.userObj.communityId;

        return combineLatest([ this.eservice.getEinkaufszettelByStatusAndUser(2, this.userObj.uid)]);

      }),
      switchMap(list => {
        this.analogList = [];
        this.analogListStatus = [];
        this.bilderList =[];
        this.bilderListFinish = [];
        this.usersanalogtemp = [];
        this.einkaufszettelObj = [];
        this.shoppinglist = [];
        this.einkaufszettelObjFinish = [];
        this.einkaufszettelObjAnalog = [];
        this.einkaufszettelalluser=[];
        this.einkaufszettelObjFinishAnalog = [];
this.shoppinglist = list[0];
console.log(this.shoppinglist);
        if(this.shoppinglist.length==0){
          this.ready=1;
          let observable = of('nothing');
          return observable;
                     }

        this.usersanalogtemp = [];

         let observables = [];


        for (let i = 0; i < this.shoppinglist.length; i++) {

          observables.push(this.comm.getUser(this.shoppinglist[i]["userId"]));
if(this.shoppinglist[i]["typ"] == "digital"){
  if(this.shoppinglist[i]["status"] ==2){
    this.einkaufszettelObj.push(this.shoppinglist[i]);
  }
  if(this.shoppinglist[i]["status"] ==3){
    this.einkaufszettelObjFinish.push(this.shoppinglist[i]);
  }

}
          if(this.shoppinglist[i]["typ"] == "analog"){
            if(this.shoppinglist[i]["status"] ==2){
              this.einkaufszettelObjAnalog.push(this.shoppinglist[i])
            }
            if(this.shoppinglist[i]["status"] ==3) {
              this.einkaufszettelObjFinishAnalog.push(this.shoppinglist[i])
            }
          }
        }

        if(observables.length==0){
          let observable = of('nothing');
          return observable;
        }else {

          return combineLatest(observables);
        }
      }),

      switchMap(allUser => {
   let usersall: any;
   console.log(this.einkaufszettelObj);
   usersall = allUser;


  this.einkaufszettelObj.forEach(value=>{

    const found = usersall.find(element => element.uid == value["userId"]);
    value.userObj = found;

        })

        this.einkaufszettelObjFinish.forEach(value=>{

          const found = usersall.find(element => element.uid == value["userId"]);
          value.userObj = found;

        })

let observables_pictures = [];

        this.einkaufszettelObjAnalog.forEach(value=>{
          let ref = this.afStore.ref(`${value.pfad}`);
          observables_pictures.push(ref.getDownloadURL());
          const found = usersall.find(element => element.uid == value["userId"]);
          value.userObj = found;

        })

        this.einkaufszettelObjFinishAnalog.forEach(value=>{
          let ref = this.afStore.ref(`${value.pfad}`);
          observables_pictures.push(ref.getDownloadURL());
          const found = usersall.find(element => element.uid == value["userId"]);
          value.userObj = found;

        })

          if(observables_pictures.length==0){
            let observable = of('nothing');
            return observable;
          }else {


            return combineLatest(observables_pictures);
          }

      }),
      switchMap(results=>{
let temp: any;
temp = results;
        let anzahl_analog = this.einkaufszettelObjAnalog.length;
        let anzahl_analog_finish = this.einkaufszettelObjFinishAnalog.length;
        let counter = 0;
        console.log(results);
        if(results!="nothing") {
          temp.forEach(value => {
            if (counter < anzahl_analog) {
              this.einkaufszettelObjAnalog[counter].url = value;
            } else {
              //this.einkaufszettelObjFinishAnalog[counter].url = value;
            }

            counter++;
          })
        }

        this.ready = 1;
        let observables = [];
return combineLatest(observables) ;

      })

    ).subscribe(result => {




    });


  }

  showGps(art) {
    art.gps = true;
  }

  resetShopping(uid) {
    if (uid) {
      if (confirm('Einkauf zurück legen?')) {
        this.eservice.resetShopping(uid);
      }
    } else {
      //   console.log('Error, keine UID');
    }
  }

  preis(wert, zahl) {
    if (zahl == 1) {
      this.price1 = wert;
    }
    if (zahl == 2) {
      this.price2 = wert;
    }
  }

  finishShopping(uid) {
    const preis = +this.price1 + +(this.price2 / 100);
    if (uid) {
      if (preis) {
        if (confirm('Einkauf als erledigt kennzeichnen?')) {

          this.eservice.finishShopping(uid, preis).then(r => console.log(r));

          }
        }
      else {
        alert('Bitte die Ausgaben angeben');
      }
    } else {
      console.log('Error, keine UID');
    }
  }

  sendToEvernote(art) {
    console.log(art);
    let notestring = 'Bemerkung: ' + art.bemerkung + '<br></br>' + 'Zieldatum: ' + art.zieldatum + '<br></br>';
    art.artikelListe.forEach(element => {
      notestring += '<en-todo/>' + element.menge + ' ' + element.einheit + ' ' + element.name + '<br></br>';
    });
    console.log(notestring);
    this.evernote.createNode(art.name, this.userObj.evernote_token, notestring).subscribe(event2 => {
      alert('Einkaufszettel wurde erfolgreich exportiert');
      console.log('event2', event2);
    });
  }



  ngOnInit(): void {
  }

}

