import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';

import { RegisterComponent } from './register/register.component';
import { AdmindashboardComponent } from './admindashboard/admindashboard.component';

import { LandingComponent } from './landing/landing.component';


import {NavbarComponent} from './navbar/navbar.component';
import { AuthGuard } from './shared/guard/auth.guard';
import {ForgotPasswordComponent} from './forgot-password/forgot-password.component';
import { UserGuard } from  './user.guard';
import { CommunityOverviewComponent } from './community-overview/community-overview.component';
import {CommunityDashboardComponent} from './community-dashboard/community-dashboard.component';
import {ImpressumComponent} from './impressum/impressum.component';
import {DatenschutzComponent} from './datenschutz/datenschutz.component';

import {TakeShoppingComponent} from './take-shopping/take-shopping.component';

import {PrintEinkaufszettelComponent} from './print-einkaufszettel/print-einkaufszettel.component';

import { MyShoppingTasksComponent } from './my-shopping-tasks/my-shopping-tasks.component';

import {AddEinkaufszettelComponent} from './add-einkaufszettel/add-einkaufszettel.component';
import {AboutusComponent} from './aboutus/aboutus.component';
import {UserComponent} from './user/user.component';
import {AccountLinkingComponent} from './account-linking/account-linking.component';
import {EvernoteComponent} from './evernote/evernote.component';
import {EinkaufszettelGlobalComponent} from './einkaufszettel-global/einkaufszettel-global.component';
import {FaqComponent} from './faq/faq.component';

const routes: Routes = [

      { path: '',  redirectTo: 'landing', pathMatch: 'full' },
     { path: 'takeShopping', component: TakeShoppingComponent, canActivate: [UserGuard] },
     { path: 'login', component: LoginComponent },
    { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'admin', component: AdmindashboardComponent, canActivate: [UserGuard] },
  { path: 'einkaufszettel', component: EinkaufszettelGlobalComponent, canActivate:[UserGuard] },
      { path: 'landing', component: LandingComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'about', component: AboutusComponent },
     { path: 'register', component: RegisterComponent },
  { path: 'print', component: PrintEinkaufszettelComponent, canActivate: [UserGuard] },
  { path: 'cd', component: CommunityDashboardComponent, canActivate: [UserGuard] },
  { path: 'evernote', component: EvernoteComponent, canActivate: [UserGuard] },
  { path: 'impressum', component: ImpressumComponent },
  { path: 'datenschutz', component: DatenschutzComponent },
     { path: 'myShopping', component: MyShoppingTasksComponent, canActivate: [UserGuard] },
               { path: 'addEinkaufszettel', component: AddEinkaufszettelComponent, canActivate: [UserGuard] },
     { path: 'communityOverview', component: CommunityOverviewComponent,  canActivate: [UserGuard] },
   { path: 'user', component: UserComponent },
  { path: 'accountlinking', component: AccountLinkingComponent }

 ];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
