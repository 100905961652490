<ng-template #einkaufszettel_uebernommen  let-modal>
  <div class="container pl-0 pr-0">
    <div class="modal-custom">
      <div class="modal-header">
        <h4 class="modal-title" >Einkaufszettel erfolgreich übernommen</h4>
        <button type="button" #button class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-md-center align-items-center pl-4 pr-4 pt-4 pb-4">

          <p class="text-center">Du hast den Einkauf erfolgreich übernommen. Den Status über all deine Einkäufe findest du hier:</p>
          <a (click)="modal.dismiss('Cross click');   this.einkaufszettelansicht.nativeElement.hidden=true;" class="btn btn-block" [routerLink]="['/myShopping']">Meine Einkäufe</a>

      </div>
    </div>
  </div>
  </div>
</ng-template>
<ng-template #einkaufszettel_ansicht id="einkaufszettel_ansicht" let-modal_ansicht>
  <div class="container pl-0 pr-0">
    <div class="modal-custom">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Einkaufszettel an diesem Ort</h4>
        <button type="button" #button class="close" aria-label="Close" (click)="modal_ansicht.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-md-center align-items-center pt-4 pb-4">

          <div class="col-auto mt-4 "*ngFor="let art of einkaufszettel_modal; let i = index">
            <div class="card" style="color: black; background-color: white" *ngIf='art.typ=="digital"'>
              <div  style="color:black">
                <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
                  <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
                  <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{einkaufszettel_users[i].firstname}}</div>
                  <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{einkaufszettel_users[i].zip}} {{einkaufszettel_users[i].address2}} </div>
                  <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                  <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                  <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
                </div>
                <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
                  <div class="row justify-content-md-center">{{art.name}} </div>
                  <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
                  <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
                  <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                  <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                  <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span><span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span></div>
                </div>
              </div>

                   <ul class="list-group list-group-flush">
                <li style="display: inline" class="list-group-item" *ngFor="let art2 of art.artikelListe;let a = index" >
                  {{a+1}}. {{art2.name}} <span *ngIf="art2.menge">(Menge: {{art2.menge}} {{art2.einheit}}) </span> <span *ngIf="art2.bemerkung">(Bemerkung: {{art2.bemerkung}}) </span>
                </li>
              </ul>
              <li class="list-group-item text-center" >
                <div class="row justify-content-md-center takeeinkauf btn btn-outline-primary">
                  <span class="cursor" (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)"><i (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)" style="" class="fas fa-clipboard-check"></i> Einkaufszettel übernehmen</span>
                </div>
              </li>
            </div>
            <div  class="card" style="color: black; background-color: white" *ngIf='art.typ=="analog"'>
              <div  style="color:black">
                <div  style="color:black">
                  <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
                    <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
                    <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{einkaufszettel_users[i].firstname}}</div>
                    <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{einkaufszettel_users[i].zip}} {{einkaufszettel_users[i].address2}} </div>
                    <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                    <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                    <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
                  </div>
                  <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
                    <div class="row justify-content-md-center">{{art.name}} </div>
                    <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
                    <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
                    <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                    <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                    <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span><span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span>></div>
                  </div>
                </div>
                  <img class="img-fluid" src="{{art.image_url}}" >
                <li class="list-group-item text-center" >
                  <div class="row justify-content-md-center takeeinkauf btn btn-outline-primary">
                    <span class="cursor" (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)"><i (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)" style="" class="fas fa-clipboard-check"></i> Einkaufszettel übernehmen</span>
                  </div>
                </li>
              </div>

            </div>
          </div>
        </div>

        </div>
      </div>
    </div>

</ng-template>

<ng-template #einkaufszettel_push id="einkaufszettel_push" let-modal_push>
  <div class="container pl-0 pr-0">
    <div class="modal-custom">
      <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Neuer Einkaufszettel</h4>
        <button type="button" #button class="close" aria-label="Close" (click)="modal_push.dismiss('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-md-center align-items-center pt-4 pb-4">

          <div class="col-auto mt-4" *ngFor="let art of einkaufszettel; let i = index">
            <div *ngIf="art.uid==showzettelid"class="card" style="color: black"   >
              <div *ngIf='art.typ=="digital"'>
                <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
                  <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
                  <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{einkaufszettel_users[i].firstname}}</div>
                  <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{einkaufszettel_users[i].zip}} {{einkaufszettel_users[i].address2}} </div>
                  <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                  <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                  <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
                </div>
                <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
                  <div class="row justify-content-md-center">{{art.name}} </div>
                  <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
                  <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
                  <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                  <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                  <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span><span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span></div>
                </div>


                <ul class="list-group list-group-flush">
                  <li style="display: inline" class="list-group-item" *ngFor="let art2 of art.artikelListe;let a = index" >
                    {{a+1}}. {{art2.name}} <span *ngIf="art2.menge">(Menge: {{art2.menge}} {{art2.einheit}}) </span> <span *ngIf="art2.bemerkung">(Bemerkung: {{art2.bemerkung}}) </span>
                  </li>
                </ul>
                <li class="list-group-item text-center" >
                  <div class="row justify-content-md-center takeeinkauf btn btn-outline-primary">
                    <span class="cursor" (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)"><i (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)" style="" class="fas fa-clipboard-check"></i> Einkaufszettel übernehmen</span>
                  </div>
                </li>
              </div>
              <div *ngIf='art.typ=="analog"'>
                <div  style="color:black">
                  <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
                    <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
                    <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{einkaufszettel_users[i].firstname}}</div>
                    <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{einkaufszettel_users[i].zip}} {{einkaufszettel_users[i].address2}} </div>
                    <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                    <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                    <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
                  </div>
                  <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
                    <div class="row justify-content-md-center">{{art.name}} </div>
                    <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
                    <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
                    <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                    <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                    <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span><span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span></div>
                  </div>
                  <img class="img-fluid" src="{{art.image_url}}" >
                  <li class="list-group-item text-center" >
                    <div class="row justify-content-md-center takeeinkauf btn btn-outline-primary">
                      <span class="cursor" (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)"><i (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)" style="" class="fas fa-clipboard-check"></i> Einkaufszettel übernehmen</span>
                    </div>
                  </li>
                </div>

              </div>


            </div>
          </div>

        </div>

      </div>
    </div>
  </div>

</ng-template>


<div class="container">
    <div *ngIf="ready==false" class="container">

    <div class="d-flex p-2  justify-content-center"><img class="spinner" src="https://besorgselfriede.org/assets/img/logomithintergrund.png" /></div>
    <div class="d-flex p-2  justify-content-center">
      <img class="img-responsive" style=" max-width: 5%; max-height:5%;
  width:100%; height:100%" src="assets/img/ci_loader.gif"/>
    </div>
  </div>
  <div [ngClass]="{hidden:!ready}" class="container justify-content-center">
  <div class="row justify-content-center">
    <h4>Einkaufszettel</h4>
  </div>
  <div class="form-group">

    <label>Deinen Standort ändern:</label>

    <input type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Suchbegriff eingeben" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
  </div>


<div class="row justify-content-md-center pt-4">
  <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="11">
    <agm-marker [latitude]="latitude" [longitude]="longitude"  [zIndex]=-3 [markerClickable]="false">
    </agm-marker>


    <div *ngFor="let value of einkaufszettel_same_places; let i=index">
      <agm-marker-cluster [zoomOnClick]="false" [styles]="cluster_styles" (clusterClick)="open(einkaufszettel_ansicht);openEinkaufszettelSamePlaces(i)">
        <div *ngFor="let i2 of [].constructor(einkaufszettel_same_places[i].times)">
        <agm-marker [latitude]="value.lat" [longitude]="value.lng" [label]="'M'">
        </agm-marker>
        </div>
      </agm-marker-cluster>


      <div>


      </div>


    </div>




    <div *ngFor="let value of einkaufszettel; let i = index">
          <agm-marker *ngIf="!value.often" (markerClick)="open(einkaufszettel_ansicht);openEinkaufszettel(i)"  [iconUrl]="{
    url: './assets/img/logomithintergrund.png',
    scaledSize: {
        width: 40,
        height: 40
    }
}" [latitude]="value.lat" [longitude]="value.lng">
    </agm-marker>

</div>



    <agm-circle [latitude]="latitude" [longitude]="longitude" [clickable]="false"
                [radius]="radius*1000"
                [strokeColor]="'black'"
                [fillColor]="'#A4DD5F'"
                [fillOpacity]="0.7">
                >
    </agm-circle>
  </agm-map>

</div>

    <div class="form-group pt-2">

      <label class="pr-2">Umkreis ändern:</label>

      <div ngbDropdown class="d-inline-block">
        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle><i class="fas fa-search-location"></i>&nbsp;+ {{radius}} km  </button>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <button (click)="changeRadius(5)" ngbDropdownItem>+5 km</button>
          <button (click)="changeRadius(10)"ngbDropdownItem>+10 km</button>
          <button (click)="changeRadius(20)"ngbDropdownItem>+20 km</button>
          <button (click)="changeRadius(30)"ngbDropdownItem>+30 km</button>
          <button (click)="changeRadius(50)"ngbDropdownItem>+50 km</button>
          <button (click)="changeRadius(100)"ngbDropdownItem>+100 km</button>
          <button (click)="changeRadius(150)"ngbDropdownItem>+150 km</button>
          <button (click)="changeRadius(200)"ngbDropdownItem>+200 km</button>
          <button (click)="changeRadius(200)"ngbDropdownItem>Alles anzeigen</button>
        </div>

  </div>
      <div class="row p-2" style="font-size: 0.6rem">
        <p><i class="fas fa-info-circle"></i>&nbsp; Hinweis: Die vollständigen Adressdaten werden aus Datenschutzgründen erst beim übernehmen von Einkaufszetteln angezeigt. Der Standort deiner Einkaufszettel wird ebenfalls aus Datenschutzgründen anhand von Ort und PLZ errechnet und kann somit leicht vom tatsächlichen Standort abweichen.</p>
      </div>
  <div class="row justify-content-md-center align-items-center pt-4 pb-4">

      <div class="col-auto mt-4" *ngFor="let art of einkaufszettel; let i = index">
        <div *ngIf="art.distance<=radius*1000"class="card" style="color: black"   >
          <div *ngIf='art.typ=="digital"'>
            <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
              <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
              <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{einkaufszettel_users[i].firstname}}</div>
              <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{einkaufszettel_users[i].zip}} {{einkaufszettel_users[i].address2}} </div>
              <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
              <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
              <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
            </div>
            <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
              <div class="row justify-content-md-center">{{art.name}} </div>
              <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
              <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
              <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
              <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
              <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span><span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span></div>
            </div>


          <ul class="list-group list-group-flush">
            <li style="display: inline" class="list-group-item" *ngFor="let art2 of art.artikelListe;let a = index" >
              {{a+1}}. {{art2.name}} <span *ngIf="art2.menge">(Menge: {{art2.menge}} {{art2.einheit}}) </span> <span *ngIf="art2.bemerkung">(Bemerkung: {{art2.bemerkung}}) </span>
              </li>
          </ul>
            <li class="list-group-item text-center" >
              <div class="row justify-content-md-center takeeinkauf btn btn-outline-primary">
                <span class="cursor" (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)"><i (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)" style="" class="fas fa-clipboard-check"></i> Einkaufszettel übernehmen</span>
              </div>
            </li>
        </div>
          <div *ngIf='art.typ=="analog"'>
            <div  style="color:black">
              <div *ngIf="art.zielperson=='mich'" class="card-header takeeinkauf">
                <div *ngIf="art.name" class="row justify-content-md-center text-left"><b>Einkaufszettel-Name: &nbsp; </b>{{art.name}} </div>
                <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{einkaufszettel_users[i].firstname}}</div>
                <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{einkaufszettel_users[i].zip}} {{einkaufszettel_users[i].address2}} </div>
                <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span></div>
              </div>
              <div *ngIf="art.zielperson=='andere'" class="card-header takeeinkauf">
                <div class="row justify-content-md-center">{{art.name}} </div>
                <div class="row justify-content-md-center" ><b>Empfänger: &nbsp;</b> {{art.zielperson_name}}</div>
                <div class="row justify-content-md-center"><b>Adresse: &nbsp;</b> {{art.zielperson_ort}} </div>
                <div *ngIf="art.bemerkung" class="row justify-content-md-center"><b>Bemerkung: &nbsp;</b> {{art.bemerkung}} </div>
                <div *ngIf="art.zieldatum" class="row justify-content-md-center"><b>Zieldatum: &nbsp;</b> {{art.zieldatum | date : 'dd.MM.yyyy'}} </div>
                <div class="row justify-content-md-center"><span style="font-size: 0.8rem" class="font-weight-lighter">(Erstellt am: {{art.cre_date.seconds * 1000| date:'dd.MM.yyyy HH:mm'}})</span><span *ngIf="art.bemerkung">(Bemerkung: {{art.bemerkung}})</span><span *ngIf="art.zieldatum">(Zieldatum: {{art.zieldatum | date : 'dd.MM.yyyy'}})</span></div>
              </div>
                 <img class="img-fluid" src="{{art.image_url}}" >
              <li class="list-group-item text-center" >
                <div class="row justify-content-md-center takeeinkauf btn btn-outline-primary">
                  <span class="cursor" (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)"><i (click)="takeEinkauf(art.uid, einkaufszettel_uebernommen)" style="" class="fas fa-clipboard-check"></i> Einkaufszettel übernehmen</span>
                </div>
              </li>
            </div>

            </div>


          </div>
        </div>

      </div>
    </div>
  </div>

</div>


