import { Injectable, NgZone } from '@angular/core';
import { User } from "../shared/services/user";
import { Community } from "../shared/services/community";

import { Einkaufszettel } from "../shared/services/einkaufszettel";
import firebase from 'firebase';
import auth = firebase.auth;
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import {Artikel} from '../shared/services/artikel';
import {Observable} from 'rxjs';
import { Timestamp } from '@firebase/firestore-types';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {
  communityObj: Community;
  artikelObj: Artikel;
  userObj: User;
  constructor(private afs:AngularFirestore,private router:Router) {


  }

  getUsers(users){


    let i =0;
    let  users2;
    for(i=0; i<users.length; i++){

      users[i] = users[i].uid;
    }
    console.log("Users in getusers users");
    console.log(users);
    //console.log(this.afs.doc(`community/${uid}`).valueChanges());
    return  this.afs.collection(`user`, ref => ref.where('uid', 'in', users)).valueChanges();

 }

  getArtikel(uid){
    //console.log(this.afs.doc(`community/${uid}`).valueChanges());
    return  this.afs.collection(`community/${uid}/artikel`).valueChanges();
  }

  getEinkaufszettel(uid){

    return  this.afs.collection(`community/${uid}/einkaufszettel`,ref=> ref.where('status','==',1)).valueChanges();
  }


  getAllEinkaufszettelAnalog(uid){

  return this.afs.collection(`community/${uid}/einkaufszettelAnalog`).valueChanges();


  }

  getAllEinkaufszettelDigital(uid){

    return this.afs.collection(`community/${uid}/einkaufszettel`).valueChanges();


  }

  getAllCommunityUser(uid){

    return this.afs.collection(`community/${uid}/users`).valueChanges();


  }

  getEinkaufszettelById(commId, uid){
    // console.log(this.afs.doc(`community/${uid}`).valueChanges());
    return  this.afs.doc(`community/${commId}/einkaufszettel/${uid}`).valueChanges();
  }
  getEinkaufszettelByIdAnalog(commId, uid){
    // console.log(this.afs.doc(`community/${uid}`).valueChanges());
    return  this.afs.doc(`community/${commId}/einkaufszettelAnalog/${uid}`).valueChanges();
  }

checkAlexaConnection(uid){
  return this.afs.collection(`oauth_alexa_token`,ref=>ref.where("uid","==",uid).limit(1)).valueChanges();
}

checkGoogleConnection(uid){
  return this.afs.collection(`oauth_google_token`,ref=>ref.where("uid","==",uid).limit(1)).valueChanges();
}

 getCommById(uid){
   console.log(uid);
    return  this.afs.doc(`community/${uid}`).valueChanges();
  }


  getAdminUser(uid){
      return  this.afs.doc(`user/${uid}`).valueChanges();
  }

  getUser(uid){
    return  this.afs.doc(`user/${uid}`).valueChanges();
  }


  getCommUsers(uid){

    return  this.afs.collection(`community/${uid}/users`).valueChanges();
  }


  getAnalogEinkaufszettel(uid){
    return  this.afs.collection(`community/${uid}/einkaufszettelAnalog`).valueChanges({idField: 'customIdName'});
  }





checkCommunityRole(commId,uid){
  return this.afs.collection(`community/${commId}/users/`,ref=>ref.where("uid","==",uid).limit(1)).valueChanges();
}

getAllArtikel(uid){
    return  this.afs.collection(`community/${uid}/artikel`).snapshotChanges();
}

removeUser(communityId,userId){

  this.afs.collection(`community/${communityId}/actions/`, ref=>ref.where("userId","==",userId)).snapshotChanges()
    .subscribe(val=>{
      if(val){
        for(let tmp of val){
          this.afs.doc(`community/${communityId}/actions/${tmp.payload.doc.id}`).delete();

        }

      }

    })





  this.afs.collection(`community/${communityId}/einkaufszettelAnalog/`, ref=>ref.where("userId","==",userId)).snapshotChanges()
    .subscribe(val=>{
      if(val){
        for(let tmp of val){
          this.afs.doc(`community/${communityId}/einkaufszettelAnalog/${tmp.payload.doc.id}`).delete();

        }

      }

    })





  this.afs.collection(`community/${communityId}/einkaufszettel/`, ref=>ref.where("userId","==",userId)).snapshotChanges()
    .subscribe(val=>{
      if(val){
        for(let tmp of val){
         this.afs.doc(`community/${communityId}/einkaufszettel/${tmp.payload.doc.id}`).delete();

        }

      }

    })


    return this.afs.collection(`community/${communityId}/users/`, ref=>ref.where("uid","==",userId)).snapshotChanges()
          .subscribe(val=>{
            if(val){

                this.afs.doc(`user/${userId}`).set({communityId:null },{
                  merge: true
                });


              for(let tmp of val){
  console.log(tmp);

             this.afs.doc(`community/${communityId}/users/${tmp.payload.doc.id}`).delete();

              }


            }
          })
        }


addArtikelToCommunity(uid,name,preis,kategorie){
  let id  = this.afs.createId();
  let date = new Date();
  var artikel = ({
    uid:id,
    name:name,
    preis:preis,
    kategorie:kategorie,
    created_at: date
  });
  console.log(uid);
  this.afs.doc(`community/${uid}/artikel/${id}`).set(artikel).then(() => {
      } ).then(() => {
        alert("Artikel erfolgreich hinzugefügt");
      }).catch((error) => {
        window.alert(error)
      })

}

saveEinkaufszettel(einkaufszettel: Einkaufszettel,uid){
    let id  = this.afs.createId();
    einkaufszettel.uid = id.toString();
    einkaufszettel.status = 1;
   return   this.afs.doc(`einkaufszettel_global/${id}`).set(einkaufszettel);

}

activateUser(communityId,userId){
  console.log(communityId);
  console.log(userId);

  this.afs.doc(`community/${communityId}/users/${userId}`).set({activated:1 },{merge: true });

}
 setEvernoteToken(uid, token){


    this.afs.doc(`user/${uid}`).set({evernote_token:token},{merge: true });

  }

  make_captain(communityId,userId){
    console.log("userID make captain service component:"+userId);
    console.log("communityid make captain service component:"+communityId);

    this.afs.doc(`community/${communityId}/users/${userId}`).set({role:2 },{merge: true });

  }
  make_community(communityId,userId){
    console.log("userID make captain service component:"+userId);
    console.log("communityid make captain service component:"+communityId);

    this.afs.doc(`community/${communityId}/users/${userId}`).set({role:1 },{merge: true });

  }
 addToCommunity(user_uid,comm_id,role?,activated?){
      if(role){
      }else{
        var role:any = 1;
      }

      if(activated){
      }else{
        var activated:any = 0;
      }

      var date = new Date();
      console.log(comm_id);
      this.afs.doc(`user/${user_uid}`).set({communityId:comm_id },{
         merge: true
       } ).then(() => {
         this.afs.doc(`community/${comm_id}/users/${user_uid}`).set({uid:user_uid,createdAt: date,role:role,activated:activated},{merge:true})
       });
 }

  createCommunity(community:Community){
    community.uid = this.afs.createId();
    this.afs.doc(`community/${community.uid}`).set(community).then(() => {

      this.addToCommunity(community.admin,community.uid,2,1);

      this.afs.doc(`user/${community.admin}`).set({communityId:community.uid },{
        merge: true
      } ).then(() => {
        this.router.navigate(['admin']).then(() =>
          {
            location.reload();
          });

      }).catch((error) => {
        window.alert(error)
      })
    });
  }
  getAllCommunities(){
    return  this.afs.collection(`community`).snapshotChanges();
  }

  getShoppingToUser(userObj:User){
        return this.afs.collection(`community/${userObj.communityId}/actions/`,ref=>ref.where("userId","==",userObj.uid)).valueChanges();
  }

  resetShopping(communityId,uid, id){

    this.afs.doc(`community/${communityId}/actions/${id}`).delete();
    this.afs.doc(`community/${communityId}/einkaufszettel/${uid}`).set({status:1}, {merge: true });
  }
  resetShoppingAnalog(communityId,uid, id){

    this.afs.doc(`community/${communityId}/actions/${id}`).delete();
    this.afs.doc(`community/${communityId}/einkaufszettelAnalog/${uid}`).set({status:1}, {merge: true });

  }
  takeEinkauf(comm_id,userId,einkaufId) {

    var date = new Date();
    var id = this.afs.createId();
    return this.afs.doc(`community/${comm_id}/actions/${id}`).set({
      einkaufId: einkaufId,
      userId: userId,
      createdAt: date,
      id: id,
      typ: "digital"
    }).catch(error=>console.log(error)).then(val => {
      this.afs.doc(`community/${comm_id}/einkaufszettel/${einkaufId}`).set({status: 2}, {merge: true});
          });
  }
    takeEinkaufAnalog(comm_id,userId,einkaufId,zettelid){
     console.log("Einkauf ID"+einkaufId);
      var date = new Date();
      let id  = this.afs.createId();
      return this.afs.doc(`community/${comm_id}/actions/${id}`).set({
        einkaufId: einkaufId,
        userId: userId,
        createdAt: date,
        id:id,

        typ:"analog"
      }).then(val=>{
        this.afs.doc(`community/${comm_id}/einkaufszettelAnalog/${zettelid}`).set({status:2, uid:zettelid}, {merge: true });

      });


  }

  finishShopping(communityId,einkaufId,preis){
        return  this.afs.doc(`community/${communityId}/einkaufszettel/${einkaufId}`).set({status:3,ausgaben:preis}, {merge: true });

  }
  finishShoppingAnalog(communityId,einkaufId,preis){
    return  this.afs.doc(`community/${communityId}/einkaufszettelAnalog/${einkaufId}`).set({status:3,ausgaben:preis}, {merge: true });

  }
}
