import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { AuthService } from '../services/auth.service';
import {User} from '../shared/services/user';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {Einkaufszettel} from '../shared/services/einkaufszettel';
import {CommunityService} from '../services/community.service';
import {Community} from '../shared/services/community';
import {combineLatest, forkJoin, Observable} from 'rxjs';
import { LocalStorage } from '@ngx-pwa/local-storage';
import {combineAll, map} from 'rxjs/operators';
import {log} from 'util';

import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
 uid:any;
  userObj: any;
  offeneEinkaufszettel: number;
  alleEinkaufszettel: number;
  communityId: any;
  communityRole: number = 1;
  users:number;
  ready: boolean = false;

  constructor(public router:Router, private com: CommunityService, public auth: AuthService, private location: Location, private route: ActivatedRoute) {

    this.auth.localStorageUserValue.subscribe((nextValue) => {
      if(nextValue){
        this.ready=true;
      } else{
        this.ready=false;
      }// this will happen on every change
    })




    }

  logout() {
    if (confirm('Möchtest du dich wirklich ausloggen?')) {
      this.auth.SignOut();
    }
  }


  goBack() {
    this.location.back();
  }

  ngOnInit(): void {


  }















}
